import { UntitledIcon } from '@faceup/icons'
import { ulTrash01 } from '@faceup/icons/ulTrash01'
import { FormItem, EditableSortableList as UiEditableSortableList } from '@faceup/ui'
import { Button, Flex } from '@faceup/ui-base'
import type { ComponentProps } from 'react'
import {
  type FieldValues,
  type Path,
  type UseFieldArrayProps,
  useFieldArray,
} from 'react-hook-form'
import { splitPropsBetweenFormInputAndFormItemWrapper } from '../functions'
import type { FormItemWrapperProps } from '../typings'
import { TextInput } from './TextInput'

type Foo = {
  id: string
  label: string
}

type EditableSortableListProps<T extends FieldValues> = UseFieldArrayProps<T> &
  Omit<
    ComponentProps<typeof UiEditableSortableList.Abstract> & FormItemWrapperProps,
    'items' | 'onMove' | 'onAddOption' | 'children'
  > & {
    shouldDisplayDeleteButton: boolean
  }

export const EditableSortableList = <T extends FieldValues>(
  props: EditableSortableListProps<T>
) => {
  const {
    formItemWrapperProps,
    formInputProps: { shouldDisplayDeleteButton, ...inputProps },
  } = splitPropsBetweenFormInputAndFormItemWrapper(props)
  const { fields, append, remove, move } = useFieldArray({
    control: props.control,
    name: props.name,
  })
  const items = fields as unknown as Foo[]
  return (
    <FormItem {...formItemWrapperProps} key={items.length}>
      <UiEditableSortableList.Abstract
        {...inputProps}
        items={items}
        onMove={(fromIndex, toIndex) => {
          move(fromIndex, toIndex)
        }}
        onAddOption={() => {
          append({
            // @ts-expect-error no time to fix it
            id: `new-${Date.now()}`,
            label: '',
          })
        }}
      >
        {({ item, key, config }) => (
          <Flex key={item.id} className='w-full'>
            <Flex align='center' gap={8} className='w-full'>
              <Flex className='w-full'>
                <TextInput
                  control={props.control}
                  name={`${props.name}.${key}.label` as Path<T>}
                  withAsterisk={false}
                  disabled={inputProps.disabled}
                />
              </Flex>
              {shouldDisplayDeleteButton && (
                <Button
                  type='text'
                  danger
                  onClick={() => {
                    remove(key)
                  }}
                  style={{ width: '32px' }}
                  icon={<UntitledIcon icon={ulTrash01} />}
                  disabled={!config.isDeletable || inputProps.disabled}
                />
              )}
            </Flex>
          </Flex>
        )}
      </UiEditableSortableList.Abstract>
    </FormItem>
  )
}
