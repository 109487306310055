import type { UntitledIconData } from '@faceup/icons'

export const ucFormItemSelect: UntitledIconData = {
  prefix: 'custom',
  name: 'ucFormItemSelect',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape: 'M14 7L8.5 13L6 10.2727',
      stroke: '#062D46',
      strokeWidth: '1.25',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  rects: [
    {
      x: '0.625',
      y: '0.625',
      width: '18.75',
      height: '18.75',
      rx: '7.375',
      stroke: '#CDDDEE',
      strokeWidth: '1.25',
    },
  ],
}
