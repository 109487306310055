import { FormItem, useAwait } from '@faceup/ui'
import { type TextAreaProps as ANTTextAreaProps, Input } from '@faceup/ui-base'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

type TextAreaProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<ANTTextAreaProps & FormItemWrapperProps, 'value' | 'defaultValue' | 'placeholder'> & {
    placeholder?: string | Promise<string>
  }
export const TextArea = <T extends FieldValues>(props: TextAreaProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, value, placeholder, ...inputProps },
  } = useCustomController(props)
  const awaitedPlaceholder = useAwait(placeholder)

  return (
    <FormItem {...formItemProps}>
      <Input.TextArea
        rows={3}
        {...inputProps}
        placeholder={awaitedPlaceholder}
        value={value ?? ''}
        onChange={e => {
          fieldOnChange(e)
          props.onChange?.(e)
        }}
      />
    </FormItem>
  )
}
