import { UntitledIcon } from '@faceup/icons'
import { ulSearchSm } from '@faceup/icons/ulSearchSm'
import { useUiBaseConfig } from '@faceup/ui-base'
import type { FieldValues } from 'react-hook-form'
import { TextInput, type TextInputProps } from './TextInput'

export const SearchInput = <T extends FieldValues>(props: TextInputProps<T>) => {
  const { token } = useUiBaseConfig()
  return (
    <TextInput
      {...props}
      prefix={<UntitledIcon icon={ulSearchSm} color={token.colorTextPlaceholder} />}
    />
  )
}
