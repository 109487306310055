import type { UntitledIconData } from '@faceup/icons'

export const ucFormItemSmallText: UntitledIconData = {
  prefix: 'custom',
  name: 'ucFormItemSmallText',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape: 'M14 8.5H6M14 5.5H6M14 11.5H6M10 14.5H6',
      stroke: '#062D46',
      strokeWidth: '1.4',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  rects: [
    {
      x: '0.625',
      y: '0.625',
      width: '18.75',
      height: '18.75',
      rx: '7.375',
      stroke: '#CDDDEE',
      strokeWidth: '1.25',
    },
  ],
}
