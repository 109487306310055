import { fetchFile } from '@ffmpeg/ffmpeg'
import JSzip from 'jszip'
import { getFFmpeg } from '../getFFmpeg'
import { fileToBinaryString } from './converters'

export const removeFileMetadata = async (originalFile: File) => {
  // It is not possible to spread File Object. (only path is returned and other values are ignored)
  const file = new File([originalFile], originalFile.name, {
    type: originalFile.type,
    lastModified: new Date().valueOf(),
  })

  switch (file.type) {
    case 'image/jpeg':
      return await processFile(file, removeJpegExif)

    // document
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.oasis.opendocument.text':
      return await processFile(file, removeDocumentMetadata)

    // spreadsheet
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return await processFile(file, removeDocumentMetadata)

    case 'application/pdf':
      return await processFile(file, removePdfMetadata)

    case 'video/mp4':
    case 'video/quicktime':
    case 'video/x-msvideo':
    case 'audio/mpeg':
    case 'audio/x-wav':
    case 'audio/ogg':
      return await processFile(file, removeAudioVideoMetadata)

    // This files does not have metadata
    case 'image/png':
    case 'application/x-latex':
    case 'application/x-tex':
    case 'text/plain':
      return {
        processed: true,
        file,
      }

    default:
      return {
        processed: false,
        file,
      }
  }
}

const processFile = async (file: File, removeFunction: (file: File) => Promise<Blob>) => {
  try {
    const newFile = await removeFunction(file)
    return {
      processed: true,
      file: new File([newFile], file.name, file),
    }
  } catch {
    return {
      processed: false,
      file,
    }
  }
}

// https://stackoverflow.com/questions/56872725/detect-metadata-about-pdf-from-javascript
const removePdfMetadata = async (file: File) => {
  const fileContent = await fileToBinaryString(file)

  // replace everything between metadata - <?xpacket begin="'... and <?xpacket end="' ... ?>
  // we will clear XMP metadata (between xpacket tags) and specific PDF columns
  const content = fileContent
    .replace(/<\?xpacket begin([\s\S]*?)<\?xpacket end.*?\?>/g, '')
    .replace(/Title\s*\(.*?\)/g, '')
    .replace(/Author\s*\(.*?\)/g, '')
    .replace(/Subject\s*\(.*?\)/g, '')
    .replace(/Keywords\s*\(.*?\)/g, '')
    .replace(/Producer\s*\(.*?\)/g, '')
    .replace(/Creator\s*\(.*?\)/g, '')
    .replace(/CreationDate\s*\(.*?\)/g, '')
    .replace(/ModDate\s*\(.*?\)/g, '')

  const array = new Uint8Array(content.length)

  for (let i = 0; i < content.length; i++) {
    array[i] = content.charCodeAt(i)
  }

  return new Blob([array], { type: file.type })
}

// Inspired by https://github.com/TorkelV/officeprops/blob/master/src/officeprops.js
const removeDocumentMetadata = async (file: File) => {
  const result = await file.arrayBuffer()
  const document = await JSzip.loadAsync(result)
  if (document.files?.['docProps/core.xml'] && document.files?.['docProps/app.xml']) {
    // microsoft office
    document.remove('docProps/core.xml')
    document.remove('docProps/app.xml')
    if (document.files?.['docProps/custom.xml']) {
      document.remove('docProps/custom.xml')
    }
    const appXML =
      '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Properties xmlns="http://schemas.openxmlformats.org/officeDocument/2006/extended-properties" xmlns:vt="http://schemas.openxmlformats.org/officeDocument/2006/docPropsVTypes"></Properties>'
    const coreXML =
      '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><cp:coreProperties xmlns:cp="http://schemas.openxmlformats.org/package/2006/metadata/core-properties" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:dcterms="http://purl.org/dc/terms/" xmlns:dcmitype="http://purl.org/dc/dcmitype/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"></cp:coreProperties>'
    document.file('docProps/core.xml', coreXML)
    document.file('docProps/app.xml', appXML)
    return await document.generateAsync({ mimeType: file.type, type: 'blob' })
  } else if (document.files?.['meta.xml']) {
    // openoffice
    document.remove('meta.xml')
    const metaXML =
      '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><office:document-meta xmlns:office="urn:oasis:names:tc:opendocument:xmlns:office:1.0" xmlns:meta="urn:oasis:names:tc:opendocument:xmlns:meta:1.0" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:xlink="http://www.w3.org/1999/xlink" office:version="1.1"></office:document-meta>'
    document.file('meta.xml', metaXML)
    return await document.generateAsync({ mimeType: file.type, type: 'blob' })
  }

  return file
}

// https://www.media.mit.edu/pia/Research/deepview/exif.html
const JPEG_START_OF_IMAGE = 0xffd8
const JPEG_END_OF_IMAGE = 0xffda

// Inspired by https://jsfiddle.net/mowglisanu/frhwm2xe/3/
const removeJpegExif = async (file: File) => {
  const result = await file.arrayBuffer()
  const dv = new DataView(result)

  if (dv.getUint16(0) !== JPEG_START_OF_IMAGE) {
    return file
  }

  let recess = 0
  const pieces = []

  for (
    let app1 = dv.getUint16(2), offset = 4;
    offset < dv.byteLength && app1 !== JPEG_END_OF_IMAGE;
    app1 = dv.getUint16(offset), offset += 2
  ) {
    if (app1 === 0xffe1) {
      pieces.push({ recess, offset: offset - 2 })
      recess = offset + dv.getUint16(offset)
    }

    offset += dv.getUint16(offset)
  }

  if (pieces.length > 0) {
    return new Blob([...pieces.map(v => result.slice(v.recess, v.offset)), result.slice(recess)], {
      type: 'image/jpeg',
    })
  }

  return file
}

// https://blog.scottlogic.com/2020/11/23/ffmpeg-webassembly.html
const removeAudioVideoMetadata = async (file: File) => {
  const ffmpeg = await getFFmpeg()
  ffmpeg.FS('writeFile', file.name, await fetchFile(file))
  // https://superuser.com/a/428039
  await ffmpeg.run(
    '-i',
    file.name,
    '-map_metadata',
    '-1',
    '-c:v',
    'copy',
    '-c:a',
    'copy',
    file.name
  )
  const output = ffmpeg.FS('readFile', file.name)
  // @ts-expect-error Ignore ArrayBuffer type
  return new Blob([output.buffer], { type: file.type })
}
