// https://github.com/mantinedev/mantine/blob/master/packages/@mantine/hooks/src/use-in-viewport/use-in-viewport.ts
import { useCallback, useRef, useState } from 'react'

export const useInViewport = <T extends HTMLElement>({ threshold }: { threshold: number }) => {
  const observer = useRef<IntersectionObserver | null>(null)
  const [inViewport, setInViewport] = useState(false)

  const ref = useCallback(
    (node: T | null) => {
      if (typeof IntersectionObserver !== 'undefined') {
        if (node && !observer.current) {
          observer.current = new IntersectionObserver(
            ([entry]) => {
              if (entry) {
                setInViewport(entry.isIntersecting)
              }
            },
            { threshold }
          )
        } else {
          observer.current?.disconnect()
        }

        if (node) {
          observer.current?.observe(node)
        } else {
          setInViewport(false)
        }
      }
    },
    [threshold]
  )

  return { ref, inViewport }
}
