import { UserContext, useAccessRights } from '@faceup/member'
import {
  Navigate,
  Outlet,
  type RouteObject,
  RouterProvider,
  RoutesProvider,
  ScrollRestoration,
  createBrowserRouter,
  useLocation,
} from '@faceup/router'
import { FeatureFlagContext } from '@faceup/ui'
import { CHARGEBEE_REDIRECT_INSTITUTION_ID_PARAM_NAME, isBase64 } from '@faceup/utils'
import { Center } from '@mantine/core'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { type PropsWithChildren, lazy, useContext, useEffect } from 'react'
import AdminLayout from '../../Components/AdminLayout'
import LoadingSpinner from '../../Components/LoadingSpinner'
import NavigationChange from '../../Components/NavigationChange'
import PageSuspense from '../../Components/PageSuspense'
import { LanguageContext } from '../../Contexts/LanguageContext'
import Login from '../../Pages/Login/Login'
import Password from '../../Pages/Login/Password'
import SSO from '../../Pages/Login/SSO'
import NotAvailable from '../../Pages/NotAvailable'
import RedirectFromChargebee from '../../Pages/RedirectFromChargebee'
import Registration from '../../Pages/Registration/Registration'
import SurveysView from '../../Pages/SurveysView'
import Typography from '../../Pages/Typography'
import RouteNotFound from '../../RouteNotFound'
import { PermissionType, ReportStatusType } from '../../__generated__/globalTypes'
import { useMotherId } from '../../hooks/useMotherId'
import { usePartner } from '../../hooks/usePartner'
import useAnalytics from '../../utils/analytics'
import Auth, { usePermissions } from '../../utils/auth'
import { isProduction } from '../../utils/envHelper'
import { useGTM } from '../../utils/gtm'
import { AppErrorBoundary } from '../AppErrorBoundary'
import { routes, useRoutes } from './useRoutes'

const PartnerMaterialsManagement = lazy(
  () => import('../../Pages/PartnerMaterialsManagement/PartnerMaterialsManagement')
)
const PartnerSettingsView = lazy(() => import('../../Pages/PartnerSettings/PartnerSettingsView'))
const CompanyDashboard = lazy(() => import('../../Pages/CompanyDashboard/Dashboard'))
const CompanyStatistics = lazy(() => import('../../Pages/CompanyStatistics/CompanyStatistics'))
const PartnerStatistics = lazy(() => import('../../Pages/PartnerStatistics/PartnerStatistics'))
const CompanyMaterials = lazy(() => import('../../Pages/CompanyMaterials/CompanyMaterials'))
const MaterialsTable = lazy(() => import('../../Pages/CompanyMaterials/MaterialsTable'))
const CompanyReportDetail = lazy(() => import('../../Pages/CompanyReportDetail/ReportDetail'))
const CompanyReports = lazy(() => import('../../Pages/CompanyReports/CompanyReports'))
const PartnerReports = lazy(() => import('../../Pages/PartnerReports/PartnerReports'))
const CompanyServiceDetail = lazy(
  () => import('../../Pages/CompanyServiceDetail/CompanyServiceDetail')
)
const CompanyServices = lazy(() => import('../../Pages/ServicesPage/ServicesPage'))
const ChangePlan = lazy(() => import('../../Pages/CompanySettings/ChangePlanPage'))
const CompanySettings = lazy(() => import('../../Pages/CompanySettings/CompanySettings'))
const AccountSettings = lazy(() => import('../../Pages/AccountSettings/AccountSettings'))
const InstitutionCustomizationView = lazy(
  () => import('../../Pages/InstitutionCustomization/InstitutionCustomizationView')
)
const PartnerAccount = lazy(() => import('../../Pages/PartnerAccount/PartnerAccount'))
// const PartnerCustomizationView = lazy(
//   () => import('../../Pages/PartnerCustomization/PartnerCustomizationView')
// )
const Institutions = lazy(() => import('../../Pages/Institutions/Institutions'))
const PDFGenerator = lazy(() => import('../../Pages/PDFViewer'))
const ReportExport = lazy(() => import('../../Pages/ReportExport/ReportExport'))
const AutomatedHotlineSetupView = lazy(
  () => import('../../Pages/InstitutionCustomization/AutomatedHotlineSetupView')
)
const CreateLiveHotlineView = lazy(
  () => import('../../Pages/InstitutionCustomization/CreateLiveHotlineView')
)
const ConnectReportSourceView = lazy(
  () => import('../../Pages/InstitutionCustomization/ConnectReportSourceView')
)
const CreateReportFormView = lazy(
  () => import('../../Pages/InstitutionCustomization/CreateReportFormView')
)
const DataManagementView = lazy(() => import('../../Pages/DataManagementView'))
const DataManagementEmployeeDetailView = lazy(
  () => import('../../Pages/DataManagementEmployeeDetailView')
)
const IntegrationsView = lazy(() => import('../../Pages/IntegrationsView'))
const AppDetailView = lazy(() => import('../../Pages/IntegrationsView/AppDetailView'))

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

export const AppRouter = () => {
  const { basenameIfAvailable } = useContext(LanguageContext)
  const { application, loading: loadingFeatureFlags } = useContext(FeatureFlagContext)
  const { logout, viewer } = useContext(UserContext)
  const { getMotherIdWithNull } = useMotherId()
  const { hasPermissions } = usePermissions()
  const { isAllowedStatistics, isVisibleForPermission } = useAccessRights()
  const { isPartnerAdministration } = usePartner()
  const routeLinks = useRoutes()
  const { identify } = useAnalytics()
  const { pageLoaded } = useGTM()

  useEffect(() => {
    if (viewer?.id) {
      identify(viewer.name ?? '', viewer.email ?? '')
    }
  }, [identify, viewer])

  useEffect(() => {
    if (viewer) {
      pageLoaded(viewer)
    }
  }, [viewer, pageLoaded])

  const isAuthenticated = Boolean(viewer?.id) || Boolean(Auth.getJwt())
  const hasAccessToMultipleInstitutions = viewer?.hasAccessToMultipleInstitutions

  /**
   * If we redirect from chargebee we wait till RedirectFromChargebee sets the motherId,
   * because we don't want to navigate to institutions.
   * Can't use useSearchParams because we are not in Router yet.
   */
  const isRedirectionFromChargebee =
    window.location.href.includes(`${CHARGEBEE_REDIRECT_INSTITUTION_ID_PARAM_NAME}=`) &&
    window.location.href.includes('id=') &&
    window.location.href.includes('state=')

  const commonRoutes: RouteObject[] = [
    /*
     * This route must be available for both logged and unlogged,
     * because we set motherId during `/register/purchase` process
     */
    {
      path: routes.registerFillInfo,
      element: <Registration step='fill-info' />,
    },
  ]

  const unauthorizedRoutes: RouteObject[] = [
    {
      index: true,
      Component: Login,
    },
    { path: routes.loginSso, Component: SSO },
    { path: routes.token, Component: Password },
    {
      path: '/registrace',
      element: <Navigate replace to={routes => routes.registerTrialRequest()} />,
    },
    {
      path: routes.register,
      element: <Navigate replace to={routes => routes.registerTrialRequest()} />,
    },

    {
      path: routes.registerPurchase,
      element: <Registration step='trial-request' isPurchase />,
    },
    {
      path: routes.registerTrialRequest,
      element: <Registration step='trial-request' />,
    },
    {
      path: routes.registerTrialRequest,
      element: <Registration step='trial-request' />,
    },
    {
      path: '*',
      element: (
        <Navigate
          to={routes => routes.home()}
          state={{ from: window.location.href.replace(window.location.origin, '') }}
        />
      ),
    },
  ]

  const loadingRoutes: RouteObject[] = [
    {
      path: '*',
      element: (
        <Center h='100vh'>
          <LoadingSpinner />
        </Center>
      ),
    },
  ]

  const notAvailableRoutes: RouteObject[] = [
    {
      path: '*',
      Component: NotAvailable,
    },
  ]

  // should be in applicationInstitutionRoutes but it's opened in new tab, see APP-1576
  const pdfGenerator = [
    {
      path: routes.pdfViewer,
      Component: PDFGenerator,
    },
  ]
  const applicationCommonRoutes: RouteObject[] = [
    {
      path: '/',
      element:
        // for now, access to multiple institutions is supported only for partners, see MemberType.ts
        hasAccessToMultipleInstitutions && !getMotherIdWithNull() ? (
          !isRedirectionFromChargebee && <Navigate replace to={routes => routes.institutions()} />
        ) : (
          <AwaitInstitution>
            <CompanyDashboard />
          </AwaitInstitution>
        ),
    },
    {
      path: routes.dashboard,
      element: (
        <AwaitInstitution>
          <CompanyDashboard />
        </AwaitInstitution>
      ),
    },
    {
      path: routes.materialsTable,
      Component: MaterialsTable,
    },
    // SERVICES
    {
      path: routes.services,
      Component: CompanyServices,
    },
    {
      path: routes.service,
      Component: CompanyServiceDetail,
    },
    // TYPOGRAPHY
    ...(!isProduction
      ? [
          {
            path: routes.typography,
            Component: Typography,
          },
        ]
      : []),
    // DATA MANAGEMENT
    // TODO: bring back isPartnerAdministration
    ...(isVisibleForPermission.ManageCategories
      ? [
          {
            path: routes.dataManagementEmployeesDetail,
            Component: DataManagementEmployeeDetailView,
          },
          {
            path: `${routes.dataManagement}/*`,
            Component: DataManagementView,
          },
        ]
      : []),
    // 404
    {
      path: '*',
      Component: RouteNotFound,
    },
    ...pdfGenerator,
  ]

  const applicationInstitutionRoutes: RouteObject[] = [
    ...(isAllowedStatistics
      ? [
          {
            path: routes.statistics,
            Component: CompanyStatistics,
          },
        ]
      : []),
    // CUSTOMIZATION
    {
      path: `${routes.institutionCustomization}/:id?/*`,
      Component: InstitutionCustomizationView,
    },
    ...(isVisibleForPermission.ManageReportingChannels
      ? [
          {
            path: routes.institutionCustomizationCreateReportForm,
            Component: CreateReportFormView,
          },
          {
            path: routes.institutionCustomizationCreateHotline,
            Component: AutomatedHotlineSetupView,
          },
          {
            path: routes.institutionCustomizationCreateLiveHotline,
            element: (
              <AwaitInstitution>
                <CreateLiveHotlineView />
              </AwaitInstitution>
            ),
          },
        ]
      : []),
    {
      path: routes.institutionCustomizationCreate,
      Component: ConnectReportSourceView,
    },
    // SETTINGS
    {
      path: `${routes.settings}`,
      element: <Navigate replace to={routes => routes.settingsInstitution()} />,
    },
    {
      path: `${routes.settings}/*`,
      Component: CompanySettings,
    },
    {
      path: routes.settingsInvoicingChangePlan,
      Component: ChangePlan,
    },
    // MY ACCOUNT
    {
      path: `${routes.account}`,
      element: <Navigate replace to={routes => routes.accountGeneral()} />,
    },
    {
      path: `${routes.account}/*`,
      Component: AccountSettings,
    },
    // REPORT
    ...(hasPermissions(PermissionType.ReportAccess)
      ? [
          {
            path: ':institutionId?/reports/status/open',
            element: (
              <Navigate
                replace
                to={routes =>
                  routes.reports({
                    statuses: [ReportStatusType.Open],
                  })
                }
              />
            ),
          },
          {
            path: ':institutionId?/reports/status/resolved',
            element: (
              <Navigate
                replace
                to={routes =>
                  routes.reports({
                    statuses: [ReportStatusType.Closed],
                  })
                }
              />
            ),
          },
          {
            path: ':institutionId?/reports/status/archived',
            element: (
              <Navigate
                replace
                to={routes =>
                  routes.reports({
                    statuses: [ReportStatusType.Archived],
                  })
                }
              />
            ),
          },
          {
            path: routes.reports,
            element: (
              <AwaitInstitution>
                <CompanyReports />
              </AwaitInstitution>
            ),
          },
          {
            path: routes.reportExport,
            Component: ReportExport,
          },
          {
            path: routes.report,
            Component: CompanyReportDetail,
          },
        ]
      : []),
    ...(hasPermissions(PermissionType.Surveys)
      ? [
          {
            path: routes.surveys,
            element: (
              <AwaitInstitution>
                <SurveysView.SurveysTableView />
              </AwaitInstitution>
            ),
          },
          {
            path: routes.surveyDetail,
            element: (
              <AwaitInstitution>
                <SurveysView.SurveyDetailView />
              </AwaitInstitution>
            ),
          },
          {
            path: `${routes.surveySubmissions}/*`,
            element: (
              <AwaitInstitution>
                <SurveysView.SurveySubmissionsView />
              </AwaitInstitution>
            ),
          },
        ]
      : []),
    // MATERIALS
    {
      path: routes.materials,
      Component: CompanyMaterials,
    },
    // INTEGRATIONS
    {
      path: `${routes.integrations}/*`,
      element: (
        <AwaitInstitution>
          <IntegrationsView />
        </AwaitInstitution>
      ),
    },
    {
      path: routes.integrationsAppDetail,
      element: (
        <AwaitInstitution>
          <AppDetailView />
        </AwaitInstitution>
      ),
    },
  ]

  const applicationPartnerRoutes: RouteObject[] = [
    {
      path: routes.institutions,
      Component: Institutions,
    },
    {
      path: routes.partnerMaterialsManagement,
      Component: PartnerMaterialsManagement,
    },
    // CUSTOMIZATION
    // {
    //   path: `${routes.partnerCustomization}/*`,
    //   Component: PartnerCustomizationView,
    // },
    // SETTINGS
    {
      path: `${routes.partnerSettings}/*`,
      Component: PartnerSettingsView,
    },
    {
      path: routes.partnerStatistics,
      Component: PartnerStatistics,
    },
    {
      path: routes.partnerReports,
      Component: PartnerReports,
    },
    // ACCOUNT
    {
      path: `${routes.partnerAccount}`,
      element: <Navigate replace to={routes => routes.partnerAccountGeneral()} />,
    },
    {
      path: `${routes.partnerAccount}/*`,
      Component: PartnerAccount,
    },
  ]

  let applicationChildrenRoutes = [...applicationCommonRoutes]

  if (isPartnerAdministration) {
    applicationChildrenRoutes = [...applicationChildrenRoutes, ...applicationPartnerRoutes]
  } else {
    applicationChildrenRoutes = [...applicationChildrenRoutes, ...applicationInstitutionRoutes]
  }

  const applicationRoutes: RouteObject[] = [
    {
      path: '/',
      element: (
        <AdminLayout
          isHidden={
            // It wants some better solution, but it is ok for now
            window.location.pathname.includes('/pdf-viewer')
          }
          logout={logout}
        >
          <PageSuspense>
            <Outlet />
          </PageSuspense>
        </AdminLayout>
      ),
      children: applicationChildrenRoutes,
    },
  ]

  let usedRoutes: RouteObject[] = [...commonRoutes]
  if (!isAuthenticated) {
    usedRoutes = [...usedRoutes, ...unauthorizedRoutes]
  }
  if (isAuthenticated && loadingFeatureFlags) {
    usedRoutes = [...usedRoutes, ...loadingRoutes]
  }
  if (isAuthenticated && !application && !loadingFeatureFlags) {
    usedRoutes = [...usedRoutes, ...notAvailableRoutes]
  }
  if (isAuthenticated && application && !loadingFeatureFlags) {
    usedRoutes = [...usedRoutes, ...applicationRoutes]
  }

  const router = sentryCreateBrowserRouter(
    [
      {
        path: '/',
        element: (
          <RoutesProvider routes={routeLinks}>
            <RedirectFromChargebee />
            <NavigationChange />
            <ScrollRestoration />
            <Outlet />
            <InstitutionIdWatcher />
          </RoutesProvider>
        ),
        children: usedRoutes,
        errorElement: <AppErrorBoundary />,
      },
    ],
    {
      basename: basenameIfAvailable,
    }
  )

  return <RouterProvider router={router} />
}

const InstitutionIdWatcher = () => {
  const location = useLocation()
  const { getMotherIdWithNull, setMotherId } = useMotherId()

  useEffect(() => {
    const firstParamInPath = location.pathname.split('/')[1]
    if (firstParamInPath && isBase64(firstParamInPath)) {
      // It's the simplest way to check if company id is in path.
      // We can't use here useSearchParams because we are not in Routers institution yet
      const isFirstParamCompanyId = atob(firstParamInPath).includes('Company:')
      if (isFirstParamCompanyId && getMotherIdWithNull() !== firstParamInPath) {
        setMotherId(firstParamInPath)
      }
    }
  }, [location.pathname, getMotherIdWithNull, setMotherId])

  return null
}

type AwaitInstitutionProps = PropsWithChildren
/**
 * We need to wait till we get motherId from the url, otherwise it throws error
 */
const AwaitInstitution = ({ children }: AwaitInstitutionProps) => {
  const { getMotherIdWithNull } = useMotherId()

  if (!getMotherIdWithNull()) {
    return (
      <Center h='100%' sx={{ flex: 1 }}>
        <LoadingSpinner />
      </Center>
    )
  }

  return <>{children}</>
}
