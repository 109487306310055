import { TokenType } from '@faceup/utils'
import { ok } from 'neverthrow'
import { getCurrentEncryptionVersion } from '../end2end/login'
import { savePersonalKeys } from '../keys'
import { changePassword, prehashPassword } from '../password'
import { createRecoveryKey, recoverPrivateKey } from '../recovery'
import { mapErr } from '../utils/general'
import { processVersionMigration } from './processVersionMigration'

export type ProcessForgottenPasswordPayload = {
  password: string
  publicKey: string
  privateKey: string
  privateKeyRecovery: string
  privateKeyRecoveryNonce: string
  recoveryKey: string | undefined
  systemPublicKey: string
  isE2EE: boolean
  version: number
}

export const processForgottenPassword = async ({
  password,
  privateKeyRecovery,
  privateKeyRecoveryNonce,
  publicKey,
  privateKey,
  recoveryKey,
  systemPublicKey,
  isE2EE,
  version,
}: ProcessForgottenPasswordPayload) => {
  if (version !== getCurrentEncryptionVersion()) {
    const migratedPayload = await processVersionMigration({
      password,
      version,
      publicKey,
      privateKey,
      systemPublicKey,
    })

    if (migratedPayload.isErr()) {
      return mapErr(migratedPayload, 'Could not migrate version')
    }

    const {
      newSalt,
      newPrivateKeyEncrypted,
      newNonce,
      newPasswordPrehash,
      recoveryKeyEncrypted,
      recoveryKeyEncryptedNonce,
      systemRecoveryKey,
      recoveryKeyPlain,
    } = migratedPayload.value
    const prehashedPassword = await prehashPassword({
      password,
      salt: newSalt,
      version: getCurrentEncryptionVersion(),
    })

    if (prehashedPassword.isErr()) {
      return mapErr(
        prehashedPassword,
        'Could not prehash password while migrating forgotten password'
      )
    }

    const { passwordKey } = prehashedPassword.value
    await savePersonalKeys({
      publicKey,
      privateKey: newPrivateKeyEncrypted,
      nonce: newNonce,
      passwordKey,
      rememberMe: false,
      version: getCurrentEncryptionVersion(),
    })

    const mutationInput = {
      tokenType: TokenType.ForgottenPassword as const,
      name: null,
      passwordPrehash: newPasswordPrehash,
      nonce: newNonce,
      salt: newSalt,
      privateKeyEncrypted: newPrivateKeyEncrypted,
      publicKey,
      recoveryKeyEncrypted,
      recoveryKeyEncryptedNonce,
      privateKeyRecovery,
      privateKeyRecoveryNonce,
      ...(!isE2EE && { recoveryKeySystemEncrypted: systemRecoveryKey }),
    }

    return ok([mutationInput, recoveryKeyPlain] as const)
  }

  const recoveryPayload = await recoverPrivateKey(
    privateKeyRecovery,
    recoveryKey ?? '',
    privateKeyRecoveryNonce
  )

  if (recoveryPayload.isErr()) {
    return mapErr(recoveryPayload, 'Could not recover private key')
  }

  const passwordPayload = await changePassword(password ?? '', {
    publicKey,
    privateKey: recoveryPayload.value,
  })

  if (passwordPayload.isErr()) {
    return mapErr(passwordPayload, 'Could not change password')
  }

  const { privateKeyEncrypted, nonce, passwordKey, salt } = passwordPayload.value
  const newRecoveryPayload = await createRecoveryKey(
    privateKeyEncrypted,
    nonce,
    passwordKey,
    recoveryKey ?? undefined
  )

  if (newRecoveryPayload.isErr()) {
    return mapErr(newRecoveryPayload, 'Could not create recovery key')
  }

  const savePayload = await savePersonalKeys({
    publicKey,
    privateKey: privateKeyEncrypted,
    nonce,
    passwordKey,
    rememberMe: false,
    version: getCurrentEncryptionVersion(),
  })

  if (savePayload.isErr()) {
    return mapErr(savePayload, 'Could not save personal keys')
  }

  const mutationInput = {
    tokenType: TokenType.ForgottenPassword as const,
    name: null,
    passwordPrehash: passwordPayload.value.passwordPrehash,
    nonce,
    salt,
    privateKeyEncrypted,
    publicKey,
    recoveryKeyEncrypted: newRecoveryPayload.value.recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce: newRecoveryPayload.value.recoveryKeyEncryptedNonce,
    privateKeyRecovery: newRecoveryPayload.value.privateKeyRecovery,
    privateKeyRecoveryNonce: newRecoveryPayload.value.privateKeyRecoveryNonce,
  }

  return ok([mutationInput, null] as const)
}
