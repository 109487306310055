import { CardNew, ContentLayout } from '@faceup/ui'

const Typography = () => (
  <ContentLayout header={<ContentLayout.Header title='Typography' />}>
    <div className='flex flex-col gap-8px'>
      <CardNew>
        <CardNew.Header extra='Extra content' hint='hint'>
          Title
        </CardNew.Header>
        <CardNew.Content>Lorem Ipsum</CardNew.Content>
      </CardNew>

      <CardNew>
        <CardNew.Section>
          <CardNew.Header extra='Extra content' hint='hint'>
            Title
          </CardNew.Header>
          <CardNew.Content>Lorem Ipsum</CardNew.Content>
        </CardNew.Section>
        <CardNew.Section>
          <CardNew.Header extra='Extra content' hint='hint'>
            Title
          </CardNew.Header>
          <CardNew.Content>Lorem Ipsum</CardNew.Content>
        </CardNew.Section>
        <CardNew.Section>
          <CardNew.Header extra='Extra content' hint='hint'>
            Title
          </CardNew.Header>
          <CardNew.Content>Lorem Ipsum</CardNew.Content>
        </CardNew.Section>
      </CardNew>
    </div>
  </ContentLayout>
)

export default Typography
