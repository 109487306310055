import { gql, useMutation } from '@apollo/client'
import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulEdit05 } from '@faceup/icons/ulEdit05'
import { ulPlusCircle } from '@faceup/icons/ulPlusCircle'
import { ulTranslate01 } from '@faceup/icons/ulTranslate01'
import { ulTrash03 } from '@faceup/icons/ulTrash03'
import { Text, useThemeColors } from '@faceup/ui'
import { useMessage, useModal } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import { ActionIcon, Group, Tooltip, UnstyledButton } from '@mantine/core'
import { forwardRef } from 'react'
import { DragListItem } from '../../../../Components/DragListItem'
import { sharedMessages } from '../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../TypedIntl'
import type {
  AddFormItemToCategory,
  AddFormItemToCategoryVariables,
  FormItemsListItem_formItem,
  FormItemsListItem_reportSource,
} from '../../../../__generated__/globalTypes'
import { ucDoubleCheck } from '../../../../customIcons/ucDoubleCheck'
import { ucFormItemDate } from '../../../../customIcons/ucFormItemDate'
import { ucFormItemEmail } from '../../../../customIcons/ucFormItemEmail'
import { ucFormItemLargeText } from '../../../../customIcons/ucFormItemLargeText'
import { ucFormItemPhoneNumber } from '../../../../customIcons/ucFormItemPhoneNumber'
import { ucFormItemSelect } from '../../../../customIcons/ucFormItemSelect'
import { ucFormItemSmallText } from '../../../../customIcons/ucFormItemSmallText'
import { useMotherId } from '../../../../hooks/useMotherId'
import { specialFormItemsDef } from '../InstitutionCustomizationOverviewView/Components/FormItem/FormItemForm'
import { Asterisk } from './Asterisk'
import { ListItemLabel } from './ListItemLabel'

const hideTranslationsCountForNow = true

const messages = defineMessages({
  deleteFormItemMessage: 'Administration.customization.formItems.deleteFormItemMessage',
  translations: 'Administration.customization.formItems.translations',
  addToCategory: 'Administration.customization.formItems.addToCategory',
  notDeletable: 'Administration.customization.formItems.notDeletable',
})

export const formItemsIcons: Record<FormItemType, UntitledIconData> = {
  [FormItemType.Select]: ucFormItemSelect,
  [FormItemType.MultiSelect]: ucDoubleCheck,
  [FormItemType.SimpleText]: ucFormItemSmallText,
  [FormItemType.Classroom]: ucFormItemLargeText,
  [FormItemType.MoreInformation]: ucFormItemLargeText,
  [FormItemType.Category]: ucFormItemSelect,
  [FormItemType.OrganizationalUnit]: ucFormItemSelect,
  [FormItemType.SenderName]: ucFormItemLargeText,
  [FormItemType.MultilineText]: ucFormItemLargeText,
  [FormItemType.Email]: ucFormItemEmail,
  [FormItemType.Date]: ucFormItemDate,
  [FormItemType.PhoneNumber]: ucFormItemPhoneNumber,
}

const FormItemsListItemFormItemFragment = gql`
  fragment FormItemsListItem_formItem on FormItem {
    id
    formItemId
    isRequired
    type

    labelTranslations {
      language
      translation
    }
  }
`

export const FormItemsListItemFragments = {
  FormItemsListItem_reportSource: gql`
    fragment FormItemsListItem_reportSource on ReportSource {
      id
      defaultLanguage
      languages
      config {
        ... on FormReportSourceConfiguration {
          id
          formItems(categoryId: $categoryId) {
            id
            ...FormItemsListItem_formItem
          }
        }
      }
    }

    ${FormItemsListItemFormItemFragment}
  `,
}

const mutations = {
  AddFormItemToCategory: gql`
    mutation AddFormItemToCategory($input: AddFormItemToCategoryInput!) {
      addFormItemToCategory(input: $input) {
        config {
          id
        }
      }
    }
  `,
}

type FormItemsListItemProps = {
  formItem: FormItemsListItem_formItem
  reportSource: FormItemsListItem_reportSource
  isDraggable: boolean
  identifier: string
  onClose: () => void
  refetchQuery: () => void
  isDeletable: boolean
  onDelete: () => void
  onEdit: () => void
  onEditTranslations: () => void
  disabled?: boolean
  selectedCategoryId: string | null
}

export const FormItemsListItem = forwardRef<HTMLDivElement, FormItemsListItemProps>(
  (props, ref) => {
    const {
      reportSource,
      formItem,
      isDraggable,
      identifier,
      isDeletable,
      onEdit,
      onEditTranslations,
      onDelete,
      onClose,
      refetchQuery,
      disabled = false,
      selectedCategoryId,
      ...passProps
    } = props
    const { formatMessage } = useIntl()
    const { getColorFromTheme } = useThemeColors()
    const { getMotherId } = useMotherId()
    const message = useMessage()
    const modal = useModal()
    const countLanguages = formItem.labelTranslations.length
    const countTranslatedLanguages = formItem.labelTranslations.filter(
      translation => translation.translation
    ).length

    const [addFormItemToCategory] = useMutation<
      AddFormItemToCategory,
      AddFormItemToCategoryVariables
    >(mutations.AddFormItemToCategory, {
      onError: error => {
        console.error(error)
        modal.error({
          title: formatMessage(sharedMessages.apiError),
          content: error.message,
        })
      },
    })

    return (
      <DragListItem
        ref={ref}
        {...passProps}
        disabled={disabled}
        icon={formItemsIcons[formItem.type]}
        isDraggable={isDraggable}
        identifier={identifier}
        isClickable
        onClick={onEdit}
        isFocused={false}
        label={
          <>
            <ListItemLabel reportSource={reportSource} formItem={formItem} />
            {formItem.isRequired && (
              <>
                &nbsp;
                <Asterisk />
              </>
            )}
          </>
        }
        secondary={
          <Group spacing='32px'>
            {reportSource.languages.length > 1 && (
              <Tooltip label={<FormattedMessage {...messages.translations} />}>
                <UnstyledButton onClick={onEditTranslations}>
                  <Group spacing='8px'>
                    <UntitledIcon icon={ulTranslate01} size={20} />
                    {!hideTranslationsCountForNow &&
                      (specialFormItemsDef.includes(formItem.type) ? (
                        <Text>
                          {countLanguages}/{countLanguages}
                        </Text>
                      ) : (
                        <Text>
                          {countTranslatedLanguages}/{countLanguages}
                        </Text>
                      ))}
                  </Group>
                </UnstyledButton>
              </Tooltip>
            )}
            <Group spacing='0'>
              <Tooltip label={<FormattedMessage {...sharedMessages.edit} />}>
                <ActionIcon onClick={onEdit}>
                  <UntitledIcon icon={ulEdit05} size={20} />
                </ActionIcon>
              </Tooltip>
              <Tooltip
                label={
                  <FormattedMessage
                    {...(isDeletable ? sharedMessages.delete : messages.notDeletable)}
                  />
                }
              >
                <div>
                  <ActionIcon
                    onClick={() => {
                      modal.confirm({
                        title: formatMessage(sharedMessages.delete),
                        content: formatMessage(messages.deleteFormItemMessage),
                        okText: formatMessage(sharedMessages.ok),
                        okType: 'primary',
                        centered: true,
                        cancelText: formatMessage(sharedMessages.cancel),
                        onOk: onDelete,
                      })
                    }}
                    disabled={!isDeletable}
                  >
                    <UntitledIcon icon={ulTrash03} size={20} />
                  </ActionIcon>
                </div>
              </Tooltip>
            </Group>
            {disabled && selectedCategoryId && (
              <Tooltip label={<FormattedMessage {...messages.addToCategory} />}>
                <ActionIcon>
                  <UntitledIcon
                    icon={ulPlusCircle}
                    size={20}
                    color={getColorFromTheme('dark.100')}
                    onClick={async () => {
                      const result = await addFormItemToCategory({
                        variables: {
                          input: {
                            motherId: getMotherId(),
                            reportSourceId: reportSource.id,
                            categoryId: selectedCategoryId,
                            formItemId: formItem.formItemId,
                          },
                        },
                      })
                      if (!result.errors) {
                        refetchQuery()
                        message.success(formatMessage(sharedMessages.savedMessage))
                      }
                    }}
                  />
                </ActionIcon>
              </Tooltip>
            )}
          </Group>
        }
      />
    )
  }
)
