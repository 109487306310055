import { useWindowEvent } from '@mantine/hooks'
import moment from 'moment-timezone'
import { type ReactNode, createContext, useCallback, useContext, useState } from 'react'
import { version as appVersion } from '../../package.json'
import { isProduction } from '../utils/envHelper'

type AppUpdaterContextProps = {
  isNewVersionAvailable: boolean
  checkVersion: (version: string) => void
  reloadAppIfNeeded: () => void
}

type AppUpdaterProviderProps = {
  children: ReactNode
}

const AppUpdaterContext = createContext<AppUpdaterContextProps>({
  isNewVersionAvailable: false,
  checkVersion: () => null,
  reloadAppIfNeeded: () => null,
})

export const AppUpdaterProvider = ({ children }: AppUpdaterProviderProps) => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState<boolean>(false)

  /*
   * For testing purposes add as bookmark
   * javascript:window.dispatchEvent(new CustomEvent("_forceNewVersionAvailable"))
   */
  useWindowEvent('_forceNewVersionAvailable', () => {
    if (!isProduction) {
      setIsNewVersionAvailable(true)
    }
  })

  const checkVersion = useCallback((version: string) => {
    const check = async () => {
      if (isVersionNewer(version, appVersion) && (await getActualFrontendVersion()) === version) {
        setIsNewVersionAvailable(true)
      }
    }
    void check()
  }, [])

  return (
    <AppUpdaterContext.Provider
      value={{
        isNewVersionAvailable,
        checkVersion,
        reloadAppIfNeeded: () => {
          if (isNewVersionAvailable) {
            window.location.reload()
          }
        },
      }}
    >
      {children}
    </AppUpdaterContext.Provider>
  )
}

export const useAppUpdater = () => useContext(AppUpdaterContext)

const splitVersion = (version: string): [number, number, number] => {
  const [major, minor, patch] = version.split('.').map(Number)
  return [major ?? 0, minor ?? 0, patch ?? 0]
}

const isVersionNewer = (newVersion: string, oldVersion: string) => {
  const [newMajor, newMinor, newPatch] = splitVersion(newVersion)
  const [oldMajor, oldMinor, oldPatch] = splitVersion(oldVersion)

  if (newMajor > oldMajor) {
    return true
  }

  if (newMajor === oldMajor && newMinor > oldMinor) {
    return true
  }

  if (newMajor === oldMajor && newMinor === oldMinor && newPatch > oldPatch) {
    return true
  }

  return false
}

const getActualFrontendVersion: () => Promise<string> = async () => {
  try {
    const response = await fetch(`/version.json?${moment().unix()}`)
    // We don't care if failed, may be run on local
    if (!response.ok) {
      return appVersion
    }
    const data = await response.json()
    return data.version
  } catch {
    return appVersion
  }
}
