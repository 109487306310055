import { type ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client'
import { processCreatePassword } from '@faceup/crypto'
import { useParams } from '@faceup/router'
import { Button, Form } from '@faceup/ui'
import { notification, useModal } from '@faceup/ui-base'
import {
  type Country,
  DEFAULT_COUNTRY,
  DEFAULT_TIMEZONE,
  type Language,
  type RegistrationReason,
  SHORT_STRING_MAX_LENGTH,
  STRING_MIN_LENGTH,
  USER_NAME_REGEX,
  convertBasenameToLanguage,
  getAvailableCurrencyFromCountry,
  isPasswordValid,
} from '@faceup/utils'
import { Box, Stack } from '@mantine/core'
import moment from 'moment-timezone'
import { type ReactNode, useContext, useEffect, useState } from 'react'
import { sharedMessages } from '../../../Shared/translations'
import { ThemeContext } from '../../../ThemeContext'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import {
  type FillInfoContentTokenQuery,
  type FillInfoContentTokenQueryVariables,
  type FillInfoCreateSubscriptionMutation,
  type FillInfoCreateSubscriptionMutationVariables,
  Institution,
  type RegisterCompanyMutation,
  type RegisterCompanyMutationVariables,
  type RequestCompanyRegistration,
  type RequestCompanyRegistrationVariables,
  type RequestPartnerRegistrationMutation,
  type RequestPartnerRegistrationMutationVariables,
  TokenType,
} from '../../../__generated__/globalTypes'
import { useMotherId } from '../../../hooks/useMotherId'
import usePhoneDefaultCountryCode from '../../../hooks/usePhoneDefaultCountryCode'
import { ThemeType } from '../../../locales'
import type { FormValue } from '../../../types'
import useAnalytics from '../../../utils/analytics'
import Auth from '../../../utils/auth'
import { useGTM } from '../../../utils/gtm'
import useRegion from '../../../utils/useRegion'
import PageTemplateUnlogged from '../../PageTemplateUnlogged'
import PurchasePlan from '../PurchasePlan'
import { Step1, Step2, Step3, Stepper } from './components'

const messages = defineMessages({
  tokenErrorTitle: 'Administration.createPassword.token.error.title',
  tokenErrorDescription: 'Administration.createPassword.token.error.description',
  startTrial: 'Administration.registration.fillInfo.startTrial',
  startPurchase: 'Administration.registration.fillInfo.startPurchase',
  nextStep: 'Administration.registration.fillInfo.nextStep',
})

type Step = 1 | 2 | 3

const query = {
  FillInfoContentToken: gql`
    query FillInfoContentTokenQuery($id: GraphQLString!) {
      token(id: $id) {
        success
        type
        email
      }

      systemInfo {
        id
        publicKey
      }
    }
  `,
}

const mutations = {
  RegisterCompany: gql`
    mutation RegisterCompanyMutation($input: RegisterCompanyInput!) {
      registerCompany(input: $input) {
        id
        token
      }
    }
  `,
  RequestPartnerRegistration: gql`
    mutation RequestPartnerRegistrationMutation($input: RequestPartnerRegistrationInput!) {
      requestPartnerRegistration(input: $input) {
        token
      }
    }
  `,
  RequestCompanyRegistration: gql`
    mutation RequestCompanyRegistration($input: RequestCompanyRegistrationInput!) {
      requestCompanyRegistration(input: $input) {
        id
        token
      }
    }
  `,
  FillInfoCreateSubscriptionMutation: gql`
    mutation FillInfoCreateSubscriptionMutation($input: CreateSubscriptionInput!) {
      createSubscription(input: $input) {
        url
      }
    }
  `,
}

export const FillInfoContent = () => {
  const { token } = useParams<'token'>()
  const { formatMessage, locale } = useIntl()
  const { project } = useContext(ThemeContext)
  const { setMotherId, getMotherId } = useMotherId()
  const { discoverByToken } = useRegion()
  const { trackSignUp, trackSignUpProgress } = useAnalytics()
  const modal = useModal()

  const [step, setStep] = useState<Step>(1)
  const [name, setName] = useState<FormValue>({ value: '', error: false })
  const [password, setPassword] = useState<FormValue>({
    value: '',
    error: false,
  })
  const [phone, setPhone] = useState<FormValue>({ value: '', error: false })
  const [institutionType, setInstitutionType] = useState<Institution | 'Partner'>(
    Institution.Company
  )
  const [institutionName, setInstitutionName] = useState<FormValue>({
    value: '',
    error: false,
  })
  const [registrationReason, setRegistrationReason] = useState<
    FormValue<RegistrationReason | null>
  >({ value: null, error: false })
  const [institutionId, setInstitutionId] = useState('')
  const [purchaseStep, setPurchaseStep] = useState<'fillInfo' | 'buy'>('fillInfo')

  const { signUpStepAdmin, signUpCompletedAdmin } = useGTM()

  const [address, setAddress] = useState<FormValue>({
    value: '',
    error: false,
  })

  const onError = (error: ApolloError) => {
    console.error(error)
    notification.error({
      message: formatMessage(sharedMessages.apiError),
      description: error.message,
    })
    setIsSubmitting(false)
  }
  const { country: defaultCountry } = usePhoneDefaultCountryCode()
  const [country, setCountry] = useState<FormValue<Country | null>>({
    value: defaultCountry,
    error: false,
  })
  const [language, setLanguage] = useState<FormValue<Language>>({
    value: convertBasenameToLanguage(locale),
    error: false,
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    window?.dataLayer?.push({
      event: `trial_request_step${step}`,
    })
  }, [step])

  useEffect(() => {
    if (defaultCountry) {
      setCountry(country => ({ ...country, value: defaultCountry }))
    }
  }, [defaultCountry])

  const [fetchTokenInfo, { data }] = useLazyQuery<
    FillInfoContentTokenQuery,
    FillInfoContentTokenQueryVariables
  >(query.FillInfoContentToken, {
    variables: {
      id: token ?? '',
    },
    onError,
    onCompleted: ({ token }) => {
      // token successfully verified
      if (
        token?.success &&
        (token?.type === TokenType.VerifyTrialEmail ||
          token?.type === TokenType.VerifyPurchaseEmail)
      ) {
        return
      }

      modal.error({
        title: formatMessage(messages.tokenErrorTitle),
        content: formatMessage(messages.tokenErrorDescription),
        onOk: () => window.location.replace('/'),
        centered: true,
      })
    },
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies(fetchTokenInfo):
  // biome-ignore lint/correctness/useExhaustiveDependencies(token):
  // biome-ignore lint/correctness/useExhaustiveDependencies(discoverByToken):
  useEffect(() => {
    const fetchToken = async () => {
      await discoverByToken(token ?? '')
      await fetchTokenInfo()
    }
    fetchToken()
  }, [])

  const isNntbSchool = project === ThemeType.NNTB && institutionType === Institution.School
  const isPurchase = data?.token?.type === TokenType.VerifyPurchaseEmail
  const email = data?.token?.email ?? null

  const onRegistrationCompleted = (jwt: string, id?: string) => {
    signUpCompletedAdmin({
      id: null,
      name: name.value,
      phone: phone.value,
      email: email ?? null,
      institutionName: institutionName.value,
      institutionType,
      country: country.value,
    })

    Auth.setJwt({ jwt, persistent: false })

    trackSignUp(institutionType, isPurchase ? 'purchase' : 'trial')

    if (isPurchase && purchaseStep === 'fillInfo') {
      if (id) {
        setMotherId(id)
        setPurchaseStep('buy')
      }
    } else {
      window.location.replace('/')
    }
  }

  const [registerCompany, { loading: loadingCompany }] = useMutation<
    RegisterCompanyMutation,
    RegisterCompanyMutationVariables
  >(mutations.RegisterCompany, {
    onError,
    onCompleted: ({ registerCompany }) => {
      onRegistrationCompleted(registerCompany?.token ?? '', registerCompany?.id ?? '')
    },
  })

  const [requestCompanyRegistration, { loading: loadingCompanyRegistration }] = useMutation<
    RequestCompanyRegistration,
    RequestCompanyRegistrationVariables
  >(mutations.RequestCompanyRegistration, {
    onError,
    onCompleted: ({ requestCompanyRegistration }) => {
      onRegistrationCompleted(
        requestCompanyRegistration?.token ?? '',
        requestCompanyRegistration?.id ?? ''
      )
    },
  })

  const [requestPartnerRegistration, { loading: loadingPartnerRegistration }] = useMutation<
    RequestPartnerRegistrationMutation,
    RequestPartnerRegistrationMutationVariables
  >(mutations.RequestPartnerRegistration, {
    onError,
    onCompleted: ({ requestPartnerRegistration }) =>
      onRegistrationCompleted(requestPartnerRegistration?.token ?? ''),
  })

  const [createSubscription] = useMutation<
    FillInfoCreateSubscriptionMutation,
    FillInfoCreateSubscriptionMutationVariables
  >(mutations.FillInfoCreateSubscriptionMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const loading =
    isSubmitting || loadingCompany || loadingCompanyRegistration || loadingPartnerRegistration
  const systemPublicKey = data?.systemInfo?.publicKey ?? ''

  const isFirstStepValid = () => {
    let isValid = true
    if (!USER_NAME_REGEX.test(name.value)) {
      setName({ ...name, error: true })
      isValid = false
    }

    if (!password.value || !isPasswordValid(password.value)) {
      setPassword({ ...password, error: true })
      isValid = false
    }

    if (!phone.value || phone.value.trim().length < 6) {
      setPhone({ ...phone, error: true })
      isValid = false
    }

    return isValid
  }
  const isThirdStepValid = () => {
    let isValid = true

    if (
      institutionName.value.length < STRING_MIN_LENGTH ||
      name.value.length > SHORT_STRING_MAX_LENGTH
    ) {
      setName({ ...name, error: true })
      isValid = false
    }

    if (!country.value) {
      setCountry({ ...country, error: true })
      isValid = false
    }

    return isValid
  }

  const isFormValid = () => {
    let isValid = true
    if (!isFirstStepValid()) {
      isValid = false
    }

    if (!isThirdStepValid()) {
      isValid = false
    }

    return isValid
  }

  const createPassword = async () => {
    const payload = await processCreatePassword({
      isE2EE: false,
      name: name.value,
      systemPublicKey,
      password: password.value,
      recoveryKey: undefined,
    })

    if (payload.isErr()) {
      return null
    }

    const [{ name: _, tokenType, ...passwordPayload }] = payload.value

    return passwordPayload
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies(institutionType):
  useEffect(() => {
    setAddress({ value: '', error: false })
    setCountry(country => ({ ...country, error: false }))
    setInstitutionId('')
  }, [institutionType])

  const register = async () => {
    setIsSubmitting(true)
    if (!isFormValid()) {
      setIsSubmitting(false)
      return
    }
    const crypto = await createPassword()
    if (!crypto) {
      setIsSubmitting(false)
      return
    }

    const input = {
      user: {
        name: name.value,
        phone: phone.value,
        token: token ?? '',
      },
      crypto: {
        ...crypto,
        recoveryKeySystemEncrypted: crypto.recoveryKeySystemEncrypted ?? '',
      },
      institution: {
        /**
         * Get rid of the special UI 'Partner' type for institution.type as it's not valid GraphQL type
         * (see apps/backend/src/gql/types/GraphQLInstitution.ts).
         * Use Company, but it's ignored on server anyway.
         */
        type: institutionType === 'Partner' ? Institution.Company : institutionType,
        organizationalUnitName: institutionName.value,
        country: country.value ?? DEFAULT_COUNTRY,
        language: language.value,
        timezone: moment.tz.guess() ?? DEFAULT_TIMEZONE,
      },
      registrationReason: registrationReason.value,
    }

    if (institutionId && institutionType === Institution.School) {
      return requestCompanyRegistration({
        variables: {
          input: {
            companyId: institutionId,
            crypto: input.crypto,
            user: input.user,
            isPurchase,
            registrationReason: registrationReason.value,
          },
        },
      })
    }

    if (institutionType === 'Partner') {
      return requestPartnerRegistration({
        variables: {
          input,
        },
      })
    }

    return registerCompany({
      variables: {
        input: {
          ...input,
          isPurchase,
        },
      },
    })
  }

  if (purchaseStep === 'buy') {
    return (
      <PurchasePlan
        currency={getAvailableCurrencyFromCountry(country.value ?? DEFAULT_COUNTRY)}
        institution={institutionType as Institution}
        onClickBuy={async buyData => {
          const { data } = await createSubscription({
            variables: {
              input: {
                motherId: getMotherId(),
                employees: buyData.employees,
                plan: buyData.plan,
                isPurchase: true,
                billingFrequency: buyData.billingFrequency,
              },
            },
          })

          if (data?.createSubscription?.url) {
            window.location.href = data.createSubscription.url
          }
        }}
      />
    )
  }

  const steps: Record<Step, ReactNode> = {
    1: (
      <Step1
        name={name}
        setName={setName}
        password={password}
        setPassword={setPassword}
        phone={phone}
        setPhone={setPhone}
      />
    ),
    2: (
      <Step2
        variant={isPurchase ? 'purchase' : 'trial'}
        institutionType={institutionType}
        setInstitutionType={setInstitutionType}
      />
    ),
    3: (
      <Step3
        isNntbSchool={isNntbSchool}
        institutionName={institutionName}
        setInstitutionName={setInstitutionName}
        institutionId={institutionId}
        setInstitutionId={setInstitutionId}
        address={address}
        setAddress={setAddress}
        country={country}
        setCountry={setCountry}
        registrationReason={registrationReason}
        setRegistrationReason={setRegistrationReason}
        language={language}
        setLanguage={setLanguage}
      />
    ),
  }

  return (
    <PageTemplateUnlogged
      publicHeaderProps={{ showLinkTo: 'signIn' }}
      cardProps={{
        styles: {
          body: {
            padding: '1.938rem 2.938rem 2.5rem',
          },
        },
      }}
      contentAboveCard={
        <Box
          sx={{
            maxWidth: '35rem',
            width: '100%',
          }}
          pb='21px'
        >
          <Stepper step={step} />
        </Box>
      }
    >
      <Form
        onSubmit={() => {
          switch (step) {
            case 1:
              if (isFirstStepValid()) {
                setStep(2)
                trackSignUpProgress(1, isPurchase ? 'purchase' : 'trial')
                signUpStepAdmin(
                  {
                    id: null,
                    name: name.value,
                    phone: phone.value,
                    email: email ?? null,
                    institutionName: null,
                    institutionType: null,
                    country: null,
                  },
                  1
                )
              }
              break
            case 2:
              setStep(3)
              trackSignUpProgress(2, isPurchase ? 'purchase' : 'trial')
              signUpStepAdmin(
                {
                  id: null,
                  name: name.value,
                  phone: phone.value,
                  email: email ?? null,
                  institutionName: null,
                  institutionType,
                  country: null,
                },
                2
              )
              break
            case 3:
              if (isThirdStepValid()) {
                void register()
              }
          }
        }}
      >
        <Stack spacing={0}>{steps[step]}</Stack>
        <Button type='submit' loading={loading} data-cy='FillInfoContent-nextStepButton'>
          {step < 3 && <FormattedMessage {...messages.nextStep} />}
          {step === 3 && isPurchase && <FormattedMessage {...messages.startPurchase} />}
          {step === 3 && !isPurchase && <FormattedMessage {...messages.startTrial} />}
        </Button>
      </Form>
    </PageTemplateUnlogged>
  )
}
