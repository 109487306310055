import EChartsReact from 'echarts-for-react'
import type { Colors } from '../../../hooks/charts/useColors'
import NoDataOverlay from '../../NoDataOverlay'
const defaultOption = {
  textStyle: {
    fontFamily: `Inter, sans-serif`,
  },
  tooltip: {
    trigger: 'item',
    axisPointer: {
      type: 'shadow',
    },
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  yAxis: {
    minInterval: 1,
  },
}
/**
 * Bar chart with category on X axis, Y axis step = 1, no stack
 */
export const BarChart = ({
  overlay,
  data,
  xAxisLabels,
  colors,
}: {
  overlay: boolean
  data: [string, unknown][]
  xAxisLabels?: string[]
  colors: Colors
}) => (
  <div className='relative w-full'>
    <NoDataOverlay visible={overlay} />
    <EChartsReact
      style={{ width: '100%', height: 250 }}
      option={{
        ...defaultOption,
        xAxis: {
          type: 'category',
          data: xAxisLabels ? xAxisLabels : data.map(([name]) => name),
          axisLabel: { interval: 0, overflow: 'truncate', width: 540 / data.length },
        },
        tooltip: { extraCssText: 'width:200px; white-space:pre-wrap;' },
        series: {
          type: 'bar',
          cursor: 'default',
          barWidth: 48,
          data: data.map(([name, value]) => ({
            type: 'bar',
            value,
            itemStyle: {
              color: colors[name],
              borderRadius: 8,
            },
          })),
        },
      }}
    />
  </div>
)
