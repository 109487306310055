import { gql, useMutation } from '@apollo/client'
import { useMotherId } from '@faceup/institution'
import { notification } from '@faceup/ui-base'
import { FormItemType, getTranslation } from '@faceup/utils'
import type { ReactNode } from 'react'
import { sharedMessages } from '../../../../../../Shared/translations'
import { useIntl } from '../../../../../../TypedIntl'
import type {
  EditSurveyFormItem_formItem,
  EditSurveyFormItem_reportSource,
  EditSurveyItem,
  EditSurveyItemVariables,
  EditSurveySelectItem,
  EditSurveySelectItemVariables,
} from '../../../../../../__generated__/globalTypes'
import { AbstractSurveyFormItem } from './AbstractSurveyFormItem'

export const EditSurveyFormItemFragments = {
  EditSurveyFormItem_reportSource: gql`
    fragment EditSurveyFormItem_reportSource on ReportSource {
      id
      defaultLanguage
    }
  `,
  EditSurveyFormItem_formItem: gql`
    fragment EditSurveyFormItem_formItem on FormItem {
      id
      order
      formItemId
      isRequired
      type
      maxLength
      minResponses
      maxResponses
      labelTranslations {
        language
        translation
      }
      hintTranslations {
        language
        translation
      }
      options(includeDeleted: false) {
        id
        labelTranslations {
          language
          translation
        }
        order
      }
    }
  `,
}

const mutations = {
  EditSurveyItem: gql`
    mutation EditSurveyItem($input: EditSurveyFormItemInput!) {
      editSurveyFormItem(input: $input) {
        config {
          id
        }
      }
    }
  `,
  EditSurveySelectItem: gql`
    mutation EditSurveySelectItem($input: EditSurveySelectFormItemInput!) {
      editSurveySelectFormItem(input: $input) {
        config {
          id
        }
      }
    }
  `,
}

type EditSurveyFormItemProps = {
  reportSource: EditSurveyFormItem_reportSource
  formItem: EditSurveyFormItem_formItem
  opened: boolean
  onClose: () => void
  onSuccess: () => void
}

export const EditSurveyFormItem = ({
  reportSource,
  formItem,
  opened,
  onClose,
  onSuccess,
}: EditSurveyFormItemProps) => {
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()

  const [editSurveyItem] = useMutation<EditSurveyItem, EditSurveyItemVariables>(
    mutations.EditSurveyItem,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const [editSurveySelectItem] = useMutation<EditSurveySelectItem, EditSurveySelectItemVariables>(
    mutations.EditSurveySelectItem,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const items: Record<FormItemType, () => ReactNode> = {
    [FormItemType.MultilineText]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.MultilineText}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.Select]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Select}
        variant='edit'
        defaultValues={{
          question: getTranslation(
            formItem.labelTranslations,
            reportSource.defaultLanguage,
            reportSource.defaultLanguage
          ),
          description: formItem.hintTranslations
            ? getTranslation(
                formItem.hintTranslations,
                reportSource.defaultLanguage,
                reportSource.defaultLanguage
              )
            : undefined,
          isRequired: formItem.isRequired,
          responses: formItem.options.map(option => ({
            id: option.id,
            label: getTranslation(
              option.labelTranslations,
              reportSource.defaultLanguage,
              reportSource.defaultLanguage
            ),
          })),
        }}
        onClose={onClose}
        opened={opened}
        onSubmit={async values => {
          const result = await editSurveySelectItem({
            variables: {
              input: {
                formItemId: formItem.id,
                channelId: reportSource.id,
                motherId: getMotherId(),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                },
                options: values.responses.map((response, order) => ({
                  id: response.id.startsWith('new-') ? null : response.id,
                  label: response.label,
                  order,
                })),
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.MultiSelect]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.MultiSelect}
        variant='edit'
        defaultValues={{
          question: getTranslation(
            formItem.labelTranslations,
            reportSource.defaultLanguage,
            reportSource.defaultLanguage
          ),
          maxResponses: formItem.maxResponses,
          minResponses: formItem.minResponses,
          showLimits:
            typeof formItem.maxResponses === 'number' || typeof formItem.minResponses === 'number',
          description: formItem.hintTranslations
            ? getTranslation(
                formItem.hintTranslations,
                reportSource.defaultLanguage,
                reportSource.defaultLanguage
              )
            : undefined,
          isRequired: formItem.isRequired,
          responses: formItem.options.map(option => ({
            id: option.id,
            label: getTranslation(
              option.labelTranslations,
              reportSource.defaultLanguage,
              reportSource.defaultLanguage
            ),
          })),
        }}
        onClose={onClose}
        opened={opened}
        onSubmit={async values => {
          const result = await editSurveySelectItem({
            variables: {
              input: {
                formItemId: formItem.id,
                channelId: reportSource.id,
                motherId: getMotherId(),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                  maxResponses: values.showLimits ? values.maxResponses : null,
                  minResponses: values.showLimits ? values.minResponses : null,
                },
                options: values.responses.map((response, order) => ({
                  id: response.id.startsWith('new-') ? null : response.id,
                  label: response.label,
                  order,
                })),
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.MoreInformation]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.MoreInformation}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.Category]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Category}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.OrganizationalUnit]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.OrganizationalUnit}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.SenderName]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.SenderName}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.Classroom]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Classroom}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.SimpleText]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.SimpleText}
        variant='edit'
        defaultValues={{
          question: getTranslation(
            formItem.labelTranslations,
            reportSource.defaultLanguage,
            reportSource.defaultLanguage
          ),
          description: formItem.hintTranslations
            ? getTranslation(
                formItem.hintTranslations,
                reportSource.defaultLanguage,
                reportSource.defaultLanguage
              )
            : undefined,
          isRequired: formItem.isRequired,
          maxLength: formItem.maxLength ?? 500,
        }}
        onClose={onClose}
        opened={opened}
        onSubmit={async values => {
          const result = await editSurveyItem({
            variables: {
              input: {
                formItemId: formItem.id,
                channelId: reportSource.id,
                motherId: getMotherId(),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                  maxLength: values.maxLength,
                },
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.Date]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Date}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.Email]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Email}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.PhoneNumber]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.PhoneNumber}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
  }

  return items[formItem.type]()
}
