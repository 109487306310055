import { ok } from 'neverthrow'
import { changePassword } from '../password'
import { createRecoveryKey, createSystemRecoveryKey } from '../recovery'
import { createErr, mapErr } from '../utils/general'

export const processVersionMigration = (payload: MigrationV1toV2Payload & { version: number }) => {
  switch (payload.version) {
    case 1:
      return processUserMigrationFromV1toV2(payload)
    default:
      return createErr('Wrong encryption version', new Error('Wrong encryption version'))
  }
}

type MigrationV1toV2Payload = {
  password: string
  publicKey: string
  privateKey: string
  systemPublicKey: string
}

const processUserMigrationFromV1toV2 = async ({
  password,
  privateKey,
  publicKey,
  systemPublicKey,
}: MigrationV1toV2Payload) => {
  const userPayload = await changePassword(password, {
    publicKey,
    privateKey,
  })

  if (userPayload.isErr()) {
    return mapErr(userPayload, 'Could not change password while migrating from v1 to v2')
  }

  const {
    privateKeyEncrypted: newPrivateKeyEncrypted,
    salt: newSalt,
    passwordPrehash: newPasswordPrehash,
    nonce: newNonce,
    passwordKey,
  } = userPayload.value

  const recoveryPayload = await createRecoveryKey(newPrivateKeyEncrypted, newNonce, passwordKey)

  if (recoveryPayload.isErr()) {
    return mapErr(recoveryPayload, 'Could not create recovery key while migrating from v1 to v2')
  }

  const systemRecoveryPayload = await createSystemRecoveryKey(
    recoveryPayload.value.recoveryKeyPlain,
    systemPublicKey
  )

  if (systemRecoveryPayload.isErr()) {
    return mapErr(
      systemRecoveryPayload,
      'Could not create system recovery key while migrating from v1 to v2'
    )
  }

  return ok({
    newNonce,
    newSalt,
    newPasswordPrehash,
    newPrivateKeyEncrypted,
    currentPasswordPrehash: password,
    systemRecoveryKey: systemRecoveryPayload.value,
    ...recoveryPayload.value,
  })
}
