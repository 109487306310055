import { UntitledIcon } from '@faceup/icons'
import { ulCheckCircle } from '@faceup/icons/ulCheckCircle'
import { FormItem } from '@faceup/ui'
import { Flex, Input, Typography, useUiBaseConfig } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import type React from 'react'
import type { ReactNode } from 'react'

type SurveyItemType = {
  id: string
  title: ReactNode
  active: boolean
  large?: boolean
}

type QuestionProps = {
  title: ReactNode
  description?: ReactNode
  large?: boolean
  required?: boolean
  secondary?: ReactNode
  unanswered?: boolean
  disabled?: boolean
} & (
  | {
      answers: SurveyItemType[]
      answer?: never
      type: FormItemType.MultiSelect | FormItemType.Select
    }
  | {
      answers?: never
      answer: string
      type: FormItemType.SimpleText
    }
)

export const Question = ({
  title,
  description,
  answers,
  type,
  answer,
  secondary,
  large = false,
  required = false,
  disabled = false,
}: QuestionProps) => {
  return (
    <FormItem
      variant='black'
      label={title}
      description={description}
      withAsterisk={required}
      secondary={secondary}
    >
      <Flex vertical gap='8px' className='mt-12px'>
        {type === FormItemType.MultiSelect || type === FormItemType.Select ? (
          answers.map(item => (
            <SurveyItem key={item.id} item={item} large={large} disabled={disabled} />
          ))
        ) : (
          <AnswerComponent answer={answer} disabled={disabled} />
        )}
      </Flex>
    </FormItem>
  )
}

type AnswerComponentProps = {
  answer: string | undefined
  disabled?: boolean
}

const AnswerComponent = ({ answer, disabled }: AnswerComponentProps) =>
  answer ? <Typography.Text disabled={disabled}>{answer}</Typography.Text> : <Input disabled />

type SurveyItemProps = {
  item: SurveyItemType
  large?: boolean
  disabled?: boolean
}

const SurveyItem = ({ item, large = false, disabled = false }: SurveyItemProps) => {
  const { token } = useUiBaseConfig()

  const activeColor: React.CSSProperties = { color: '#FFFFFF' }
  const activeStyles: React.CSSProperties = {
    backgroundColor: token.colorPrimary,
    border: 'none',
    ...activeColor,
  }

  return (
    <Flex
      style={{
        minHeight: large ? '54px' : '48px',
        borderRadius: token.borderRadiusLG,
        backgroundColor: disabled ? '#9DB0DF14' : '#FFFFFF',
        border: `1px solid ${token.colorBorder}`,
        padding: '16px',
        ...(item.active ? activeStyles : {}),
      }}
      align='center'
      justify='space-between'
    >
      <Typography.Text
        style={{
          ...(item.active ? activeColor : {}),
        }}
        disabled={disabled}
      >
        {item.title}
      </Typography.Text>
      {item.active && <UntitledIcon icon={ulCheckCircle} size={20} />}
    </Flex>
  )
}
