import { Flex, Skeleton, Typography } from '@faceup/ui-base'
import type { HTMLAttributes, ReactNode } from 'react'
import { HintIcon } from '../AdminLayout'
import { CardProvider, useCard } from './CardContext'
import { SectionProvider, useSection } from './SectionContext'
import styles from './assets/CardNew.module.css'

export type CardNewProps = {
  loading?: boolean
} & Omit<HTMLAttributes<HTMLDivElement>, 'title'>

export const CardNew = (props: CardNewProps) => {
  const { children, loading = false, className, ...otherProps } = props

  return (
    <CardProvider value={{ loading }}>
      <div className={`${styles.cardWrapper}  ${className}`} {...otherProps}>
        {children}
      </div>
    </CardProvider>
  )
}

export type SectionProps = {
  children?: ReactNode
  loading?: boolean
}

export const Section = ({ children, loading = false }: SectionProps) => {
  return (
    <SectionProvider value={{ loading }}>
      <div className={styles.segmentWrapper}>
        {children}
        <hr className={styles.divider} />
      </div>
    </SectionProvider>
  )
}

type HeaderProps = {
  children: ReactNode
  extra?: ReactNode
  hint?: string
}

const Header = ({ children, extra, hint }: HeaderProps) => {
  const loading = useLoading()

  return (
    <div className={styles.titleWrapper}>
      <Typography.Title level={5} className={styles.title}>
        {loading ? (
          <Skeleton className={styles.skeleton} />
        ) : (
          <Flex align='center' gap='small'>
            {children}
            {hint && <HintIcon title={hint} />}
          </Flex>
        )}
      </Typography.Title>
      {!loading && extra && <Flex>{extra}</Flex>}
    </div>
  )
}

type ContentProps = { children: ReactNode }

export const Content = ({ children }: ContentProps) => {
  const loading = useLoading()

  return (
    <div className={styles.contentWrapper}>
      {loading ? <Skeleton className={styles.skeleton} /> : children}
    </div>
  )
}

const useLoading: () => boolean = () => {
  const { loading: loadingCard } = useCard()
  const { loading: loadingSection } = useSection()
  return loadingCard || loadingSection
}

CardNew.Section = Section
CardNew.Header = Header
CardNew.Content = Content
