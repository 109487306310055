import { gql, useLazyQuery } from '@apollo/client'
import { FormItemType, type Language, getTranslation } from '@faceup/utils'
import { defineMessages, useIntl } from '../../../TypedIntl'
import type {
  SurveyExportQuery,
  SurveyExportQueryVariables,
} from '../../../__generated__/globalTypes'
import useCsv from '../../../hooks/useCsv'
import useDownload from '../../../hooks/useDownload'
import {
  type ExportColumnSchema,
  type Submission,
  useSurveyExportSchema,
} from '../../../hooks/useExportSchema'
import useXlsx from '../../../hooks/useXlsx'

const messages = defineMessages({
  survey: 'Administration.reportSource.survey',
})

const query = {
  SurveyExportQuery: gql`
    query SurveyExportQuery($motherId: CompanyGlobalId!, $reportSourceId: ReportSourceGlobalId!) {
      survey(motherId: $motherId, reportSourceId: $reportSourceId) {
        id
        name
        defaultLanguage
        cases {
          ... on Submission {
            id
            tag
            submittedAt
            answers {
              id
              values
              formItem {
                id
                formItemId
                type
                isRequired
                labelTranslations {
                  language
                  translation
                }
                hintTranslations {
                  language
                  translation
                }
                options {
                  id
                  labelTranslations {
                    language
                    translation
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
}

export const useSurveyExport = () => {
  const { formatMessage } = useIntl()

  const createCsv = useCsv()
  const createXlsx = useXlsx()
  const downloadFile = useDownload()

  const [getSurveyForExport] = useLazyQuery<SurveyExportQuery, SurveyExportQueryVariables>(
    query.SurveyExportQuery,
    {
      fetchPolicy: 'network-only',
    }
  )

  const { surveyDetailSection } = useSurveyExportSchema()

  const fetchSurvey = async (motherId: string, reportSourceId: string) => {
    const surveyData = await getSurveyForExport({
      variables: { motherId, reportSourceId },
    })

    return surveyData.data?.survey ?? null
  }

  const extractSubmissions = (survey: SurveyExportQuery['survey'], selectedAnswers?: string[]) =>
    survey?.cases.filter(
      _case => _case.__typename === 'Submission' && (selectedAnswers?.includes(_case.id) ?? true)
    ) ?? []

  const extractAnswers = (survey: SurveyExportQuery['survey'], selectedAnswers?: string[]) =>
    extractSubmissions(survey, selectedAnswers).flatMap(_case => _case.answers)

  const createSection = (answers: ReturnType<typeof extractAnswers>, defaultLanguage: Language) => {
    const formItems = Object.values(
      answers.reduce(
        (acc, answer) => ({
          ...acc,
          [answer.formItem.id]: answer.formItem,
        }),
        {} as Record<string, (typeof answers)[0]['formItem']>
      )
    )

    const columns = formItems.map<ExportColumnSchema<Submission>>(formItem => ({
      name:
        getTranslation(formItem.labelTranslations, defaultLanguage, defaultLanguage) +
        (formItem.isRequired ? '*' : ''),
      type: 'string',
      getValue: submission => submission.formItems[formItem.id] ?? '',
    }))

    const section = columns.reduce(
      (acc, column) => ({
        ...acc,
        columns: [...acc.columns, column],
      }),
      surveyDetailSection
    )

    return section
  }

  const createData = (
    answers: ReturnType<typeof extractAnswers>,
    submissions: ReturnType<typeof extractSubmissions>,
    defaultLanguage: Language
  ) => {
    const optionsToLabel = new Map(
      answers
        .flatMap(answer => answer.formItem.options)
        .map(
          option =>
            [
              option.id,
              getTranslation(option.labelTranslations, defaultLanguage, defaultLanguage),
            ] as const
        )
    )

    const data = submissions.map((submission, index) => ({
      id: index + 1,
      submittedAt: submission.submittedAt,
      formItems: submission.answers.reduce((acc, answer) => {
        const values =
          answer.formItem.type === FormItemType.SimpleText
            ? answer.values
            : answer.values.map(value => optionsToLabel.get(value))

        return {
          ...acc,
          [answer.formItem.id]: values.join(','),
        }
      }, {}),
    }))

    return data
  }

  const exportSurvey =
    (
      formatter: (
        section: ReturnType<typeof createSection>,
        data: ReturnType<typeof createData>,
        name: string
      ) => Promise<File>
    ) =>
    async (motherId: string, reportSourceId: string, selectedAnswers?: string[]) => {
      const survey = await fetchSurvey(motherId, reportSourceId)
      if (!survey) {
        return
      }

      const submissions = extractSubmissions(survey, selectedAnswers)
      const answers = extractAnswers(survey, selectedAnswers)
      const section = createSection(answers, survey.defaultLanguage)
      const data = createData(answers, submissions, survey.defaultLanguage)
      const name = `${formatMessage(messages.survey)}_${survey.name}`
      const file = await formatter(section, data, name)

      downloadFile(file)
    }

  const formatSurveyToXlsx = (
    section: ReturnType<typeof createSection>,
    data: ReturnType<typeof createData>,
    name: string
  ) => createXlsx({ sections: [section] }, [data], `${name}.xlsx`)

  const formatSurveyToCsv = (
    section: ReturnType<typeof createSection>,
    data: ReturnType<typeof createData>,
    name: string
  ) => Promise.resolve(createCsv(section, data, `${name}.csv`))

  return {
    exportSurveyToCsv: exportSurvey(formatSurveyToCsv),
    exportSurveyToXlsx: exportSurvey(formatSurveyToXlsx),
  }
}
