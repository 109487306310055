import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { Button, Typography } from '@faceup/ui-base'
import { LoadingOverlay } from '@mantine/core'
import type { ReactNode } from 'react'
import styles from './NoDataOverlay.module.css'

type NoDataOverlayProps = {
  visible: boolean
  icon: UntitledIconData
  label: ReactNode
  description?: ReactNode
  button?: {
    icon?: UntitledIconData
    label: ReactNode
    onClick: () => void
  }
  isTransparentBackground?: boolean
}

export const NoDataOverlay = ({
  visible,
  icon,
  label,
  description,
  button,
  isTransparentBackground,
}: NoDataOverlayProps) => (
  <LoadingOverlay
    overlayOpacity={isTransparentBackground ? 0 : 1}
    zIndex={1}
    visible={visible}
    loader={
      <div className={styles.loaderWrapper}>
        <div className={styles.iconWrapper}>
          <UntitledIcon icon={icon} size={20} />
        </div>
        <div className={styles.textWrapper}>
          <Typography.Title level={5}>{label}</Typography.Title>
          <Typography.Text type='secondary'>{description}</Typography.Text>
        </div>
        {button && (
          <Button
            type='primary'
            icon={<UntitledIcon icon={button.icon ?? ulPlus} />}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        )}
      </div>
    }
  />
)
