import { gql } from '@apollo/client'
import { AnswerCard } from '@faceup/ui'
import { Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import { BarChart } from '../../../Components/Statistics/Charts/BarChart'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import type { AnswersChart_submissionStatistics } from '../../../__generated__/globalTypes'
import { useColors } from '../../../hooks/charts/useColors'

const messages = defineMessages({
  xAnswers: 'Administration.surveys.submissions.statistics.xAnswers',
})

type SurveyChartProps = {
  label: ReactNode
} & {
  answersCount: AnswersChart_submissionStatistics['answersCount']
  statistics: Omit<AnswersChart_submissionStatistics['statistics'][number], '__typename'>[]
}

export const AnswersChartFragments = {
  AnswersChart_submissionStatistics: gql`
    fragment AnswersChart_submissionStatistics on AnswersStatistics {
      answersCount
      statistics {
        label
        count
      }
    }
  `,
}

export const AnswersChart = ({ label, statistics, answersCount }: SurveyChartProps) => {
  const colors = useColors(statistics.reduce((acc, { label }) => ({ ...acc, [label]: 0 }), {}))
  return (
    <AnswerCard>
      <div className='p-24px'>
        <AnswerCard.Title title={label} />
        <Typography.Text size='sm' type='secondary' strong>
          <FormattedMessage {...messages.xAnswers} values={{ count: answersCount }} />
        </Typography.Text>
        <BarChart
          data={statistics.map(({ label, count }) => [label, count])}
          colors={colors}
          overlay={!statistics.length}
        />
      </div>
    </AnswerCard>
  )
}
