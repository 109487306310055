import { UntitledIcon } from '@faceup/icons'
import { Box, Flex } from '@mantine/core'
import type { ReactNode } from 'react'
import { ucDots2Grid } from '../customIcons'

type SimpleDragListItemProps = {
  isDraggable: boolean
  /**
   * Used when nested drag lists need to be distinguished.
   */
  identifier?: string
  children: ReactNode
}

export const SimpleDragListItem = (props: SimpleDragListItemProps) => {
  const { isDraggable, children, identifier = '' } = props
  return (
    <div className={identifier ? `${identifier}-item` : undefined}>
      <Flex sx={{ width: '100%' }} gap='8px'>
        <Box
          className={isDraggable ? `draggable ${identifier}` : ''}
          sx={{
            cursor: isDraggable ? 'move' : undefined,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              pointerEvents: 'none',
              opacity: isDraggable ? 1 : 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <UntitledIcon icon={ucDots2Grid} size={20} color='#6F91B7' />
          </Box>
        </Box>
        {children}
      </Flex>
    </div>
  )
}
