import { AlertBar } from '@faceup/ui'
import { useAppUpdater } from '../Contexts/AppUpdaterProvider'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  text: 'Administration.newVersion.text',
  button: 'Administration.newVersion.button',
})

export const NewVersionAlertBar = () => {
  const { isNewVersionAvailable, reloadAppIfNeeded } = useAppUpdater()

  if (!isNewVersionAvailable) {
    return null
  }

  return (
    <AlertBar
      color='blue'
      button={{
        text: <FormattedMessage {...messages.button} />,
        onClick: reloadAppIfNeeded,
      }}
    >
      <FormattedMessage {...messages.text} />
    </AlertBar>
  )
}
