import { gql } from '@apollo/client'
import { useNavigate } from '@faceup/router'
import { AlertBar } from '@faceup/ui'
import type { ComponentProps, ReactNode } from 'react'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import type { PaymentNoticeAlertBar_company } from '../../__generated__/globalTypes'
import { useAlertOpen } from '../../hooks/useAlertOpen'
import useSubscriptionDetail, {
  type SubscriptionDetail,
  SubscriptionDetailFragments,
} from '../../hooks/useSubscriptionDetail'
import { PaymentNoticeMessage, PaymentNoticeMessageFragments } from './PaymentNoticeMessage'

const messages = defineMessages({
  buy: 'Administration.trial.buy',
  renew: 'Administration.paymentNotice.renew',
})

export const PaymentNoticeAlertBarFragments = {
  PaymentNoticeAlertBar_company: gql`
        fragment PaymentNoticeAlertBar_company on Company {
            id

      billing {
        id
        subscriptionStatus
        subscriptionEnd
      }

      ...SubscriptionDetail_company
      ...PaymentNoticeMessage_company
    }

    ${SubscriptionDetailFragments.SubscriptionDetail_company}
    ${PaymentNoticeMessageFragments.PaymentNoticeMessage_company}
    `,
}

type PaymentNoticeAlertBarProps = {
  company: PaymentNoticeAlertBar_company
}

export const PaymentNoticeAlertBar = ({ company }: PaymentNoticeAlertBarProps) => {
  const inTrialAlert = useAlertOpen({
    key: 'inTrial',
  })
  const nonRenewingAlert = useAlertOpen({
    key: 'nonRenewing',
  })

  const getSubscriptionDetail = useSubscriptionDetail()

  const navigate = useNavigate()

  const { state } = getSubscriptionDetail(company)

  const states: Record<
    SubscriptionDetail['state'],
    {
      alertBar: Pick<ComponentProps<typeof AlertBar>, 'color'>
      hook: ReturnType<typeof useAlertOpen>
      button: {
        text: ReactNode
      }
    } | null
  > = {
    trialExpired: null,
    cancelled: null,
    inTrial: {
      alertBar: { color: 'orange' },
      hook: inTrialAlert,
      button: {
        text: <FormattedMessage {...messages.buy} />,
      },
    },
    future: null,
    active: null,
    nonRenewing: {
      alertBar: { color: 'orange' },
      hook: nonRenewingAlert,
      button: {
        text: <FormattedMessage {...messages.renew} />,
      },
    },
  }
  if (!states[state]) {
    return null
  }
  return (
    <>
      <AlertBar
        {...states[state].hook}
        {...states[state].alertBar}
        button={{
          ...states[state].button,
          onClick: () => {
            navigate(routes => routes.settingsInvoicing())
          },
        }}
      >
        <PaymentNoticeMessage institution={company} isSingleColor isWrapped={false} />
      </AlertBar>
    </>
  )
}
