import { readClosingComment } from '@faceup/crypto'
import { useCallback } from 'react'
import { useCryptoErrorHandler } from '../utils'

export const useClosingComment = () => {
  const handleError = useCryptoErrorHandler()

  const decrypt = useCallback(
    async (body: string | null, nonce: string | null, encryptionKey: string | null) => {
      const payload = await readClosingComment(body ?? '', nonce ?? '', encryptionKey ?? '')

      if (payload.isErr()) {
        handleError(payload.error.message)
        throw new Error(payload.error.message)
      }

      return payload.value
    },
    [handleError]
  )
  return { decrypt }
}
