import { TokenType } from '@faceup/utils'
import { ok } from 'neverthrow'
import { prehashPassword } from '../password'
import { decryptPrivateKey } from '../recovery'
import { mapErr } from '../utils/general'

export type ProcessMigrationToSSOPayload = {
  password: string
  salt: string
  privateKey: string
  nonce: string
}

export const processMigrationToSSO = async ({
  password,
  nonce,
  privateKey,
  salt,
}: ProcessMigrationToSSOPayload) => {
  const prehashedPassword = await prehashPassword({ password, salt })
  if (prehashedPassword.isErr()) {
    return mapErr(prehashedPassword, 'Could not prehash password')
  }

  const { passwordKey } = prehashedPassword.value
  const privateKeyPayload = await decryptPrivateKey(privateKey, nonce, passwordKey)

  if (privateKeyPayload.isErr()) {
    return mapErr(privateKeyPayload, 'Could not decrypt private key when migrating to SSO')
  }

  return ok([
    {
      tokenType: TokenType.MigrateToSSO as const,
      privateKey: privateKeyPayload.value,
    },
    null,
  ] as const)
}
