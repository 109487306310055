import { UntitledIcon } from '@faceup/icons'
import { useThemeColors } from '@faceup/ui'
import { Avatar, Grid, Group, Image, Stack, Text, Title } from '@mantine/core'
import type { ReactNode } from 'react'
import CheckIcon from './assets/check-icon.svg'
import { DotsMesh, UpgradeButtons } from './components'
import type { UpsellData } from './upsellData'

type UpsellPageContentProps = {
  upsell: UpsellData
  children: ReactNode
}

export const UpsellPageContent = (props: UpsellPageContentProps) => {
  const { children, upsell } = props
  const { icon, title, description, features, image, showUpsell } = upsell

  const { themeColors } = useThemeColors()

  if (!showUpsell) {
    return <>{children}</>
  }

  return (
    <Grid my='1rem' mx='2rem' gutter='6rem' justify='center' align='center' sx={{ flex: 1 }}>
      <Grid.Col span={5}>
        <Stack spacing='2rem'>
          <Avatar color='blue' size={56}>
            <UntitledIcon icon={icon} />
          </Avatar>
          <Stack spacing='1rem'>
            <Title order={3}>{title}</Title>
            <Text c='prussianBlue.8'>{description}</Text>
            {features.map((feature, i) => (
              // biome-ignore lint/suspicious/noArrayIndexKey:
              <Group key={i} spacing={4}>
                <img width={16} height={16} src={CheckIcon} alt='' />
                <Text c='prussianBlue.8'>{feature}</Text>
              </Group>
            ))}
          </Stack>
          <UpgradeButtons upsell={upsell} />
        </Stack>
      </Grid.Col>
      <Grid.Col span={7} pos='relative'>
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <DotsMesh rows={4} cols={12} />
        </div>
        {image && (
          <Image style={{ position: 'relative', zIndex: 1 }} src={image.toString()} alt='' />
        )}
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
          }}
        >
          <DotsMesh rows={12} cols={12} color={themeColors.success[20]} />
        </div>
      </Grid.Col>
    </Grid>
  )
}
