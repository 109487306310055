import type { Branded } from '@faceup/utils'
import { ok } from 'neverthrow'
import {
  FILE_CHUNK_ENCRYPTED_SIZE,
  FILE_CHUNK_SIZE,
  FILE_CHUNK_SUBKEY_ID_LEN,
  FILE_ENCRYPTION_CONTEXT,
  crypto_secretstream_xchacha20poly1305_KEYBYTES,
} from '../utils/constants'
import { createErr, getSodium, mapErr } from '../utils/general'
import { KeyDerivation } from './derivation'

export type StreamKey = Branded<Uint8Array, 'StreamKey'>

type FileLike = {
  slice: (from: number, to: number) => ArrayBufferLike | ArrayLike<number>
  size: number
}

export class Stream {
  public static async encrypt(key: StreamKey, file: FileLike) {
    const sodium = await getSodium()
    const subkeyId = await KeyDerivation.generateSubkeyId()
    const streamKey = await KeyDerivation.deriveKey(
      crypto_secretstream_xchacha20poly1305_KEYBYTES,
      subkeyId,
      FILE_ENCRYPTION_CONTEXT,
      KeyDerivation.toKey(key)
    )

    if (streamKey.isErr()) {
      return mapErr(streamKey, 'Could not derive stream key when encrypting file')
    }

    try {
      const { header, state } = sodium.crypto_secretstream_xchacha20poly1305_init_push(
        streamKey.value
      )
      const encryptedChunks: Uint8Array[] = []

      let chunkCounter = 0
      do {
        const from = chunkCounter * FILE_CHUNK_SIZE
        const to = (chunkCounter + 1) * FILE_CHUNK_SIZE

        // @ts-expect-error Ignore ArrayBuffer type
        const chunk = new Uint8Array(file.slice(from, to))
        const tag =
          to > file.size
            ? sodium.crypto_secretstream_xchacha20poly1305_TAG_FINAL
            : sodium.crypto_secretstream_xchacha20poly1305_TAG_MESSAGE

        const encryptedChunk = sodium.crypto_secretstream_xchacha20poly1305_push(
          state,
          chunk,
          null,
          tag
        )

        encryptedChunks.push(encryptedChunk)
        chunkCounter += 1
      } while (chunkCounter * FILE_CHUNK_SIZE < file.size)

      return ok([header, new Uint8Array([subkeyId]), ...encryptedChunks])
    } catch (e) {
      return createErr('Could not encrypt file with stream key', e)
    }
  }

  public static async decrypt(key: StreamKey, file: FileLike) {
    const sodium = await getSodium()

    try {
      const header = new Uint8Array(
        // @ts-expect-error Ignore ArrayBuffer type
        file.slice(0, sodium.crypto_secretstream_xchacha20poly1305_HEADERBYTES)
      )
      const subKeyIdBuffer = new Uint8Array(
        // @ts-expect-error Ignore ArrayBuffer type
        file.slice(
          sodium.crypto_secretstream_xchacha20poly1305_HEADERBYTES,
          sodium.crypto_secretstream_xchacha20poly1305_HEADERBYTES + FILE_CHUNK_SUBKEY_ID_LEN
        )
      )
      const subKeyId = KeyDerivation.toSubkeyId(subKeyIdBuffer[0] ?? 0)

      const fileKey = await KeyDerivation.deriveKey(
        crypto_secretstream_xchacha20poly1305_KEYBYTES,
        subKeyId,
        FILE_ENCRYPTION_CONTEXT,
        KeyDerivation.toKey(key)
      )

      if (fileKey.isErr()) {
        return mapErr(fileKey, 'Could not derive file key when decrypting file')
      }

      const state = sodium.crypto_secretstream_xchacha20poly1305_init_pull(header, fileKey.value)
      const decryptedChunks: Uint8Array[] = []
      const offset = FILE_CHUNK_SUBKEY_ID_LEN + header.length

      let chunkCounter = 0
      do {
        const from = chunkCounter * FILE_CHUNK_ENCRYPTED_SIZE + offset
        const to = (chunkCounter + 1) * FILE_CHUNK_ENCRYPTED_SIZE + offset
        // @ts-expect-error Ignore ArrayBuffer type
        const chunk = new Uint8Array(file.slice(from, to))
        const decryptedChunk = sodium.crypto_secretstream_xchacha20poly1305_pull(state, chunk, null)

        decryptedChunks.push(decryptedChunk.message)
        chunkCounter += 1
      } while (chunkCounter * FILE_CHUNK_ENCRYPTED_SIZE < file.size)

      return ok(decryptedChunks)
    } catch (e) {
      return createErr('Could not decrypt file with stream key', e)
    }
  }

  public static toStreamKey(key: Uint8Array) {
    return key as StreamKey
  }
}
