import { gql } from '@apollo/client'
import {
  BillingFrequency,
  CHARGEBEE_UNLIMITED_MEMBERS,
  type Currency,
  Institution,
  omitNullInArray,
} from '@faceup/utils'
import { useCallback } from 'react'
import { defineMessages, useIntl } from '../../../TypedIntl'
import type { useEmployeesCountFragments_priceList } from '../../../__generated__/globalTypes'
import { useGlobalInfo } from '../../../locales'
import { CompanyInvoicingInstitutionAndSchoolFragment } from '../CompanyInvoicing/CompanyInvoicingInstitutionAndSchoolFragment'

const messages = defineMessages({
  lowerThan: 'Administration.companyInvoicing.employeeCount.lowerThan',
  higherThan: 'Administration.companyInvoicing.employeeCount.higherThan',
})

export const useEmployeesCountFragments = {
  useEmployeesCountFragments_priceList: gql`
    fragment useEmployeesCountFragments_priceList on PriceList {
      monthly {
        ...CompanyInvoicingInstitutionAndSchoolFragment
      }
      yearly {
        ...CompanyInvoicingInstitutionAndSchoolFragment
      }
      quarterly {
        ...CompanyInvoicingInstitutionAndSchoolFragment
      }
    }

    ${CompanyInvoicingInstitutionAndSchoolFragment}
  `,
}

const getBillingFrequency = (billingFrequency: BillingFrequency) => {
  switch (billingFrequency) {
    case BillingFrequency.Monthly:
      return 'monthly'
    case BillingFrequency.Yearly:
      return 'yearly'
    case BillingFrequency.Quarterly:
      return 'quarterly'
  }
}

const getInstitution = (institution: Institution) => {
  switch (institution) {
    case Institution.School:
      return 'school'
    case Institution.Company:
      return 'company'
  }
}

type ListVariant = NonNullable<
  | NonNullable<NonNullable<useEmployeesCountFragments_priceList['monthly']>['company']>[number]
  | NonNullable<NonNullable<useEmployeesCountFragments_priceList['monthly']>['school']>[number]
  | NonNullable<NonNullable<useEmployeesCountFragments_priceList['yearly']>['company']>[number]
  | NonNullable<NonNullable<useEmployeesCountFragments_priceList['yearly']>['school']>[number]
>

export const useEmployeesCount = (priceList: useEmployeesCountFragments_priceList) => {
  const { institution } = useGlobalInfo()
  const { formatMessage } = useIntl()
  const getPriceVariants = useCallback(
    (billingFrequency: BillingFrequency, currency: Currency): ListVariant[] => {
      if (!institution) {
        return []
      }
      return (
        omitNullInArray(
          priceList[getBillingFrequency(billingFrequency)]?.[getInstitution(institution)] ?? []
        ).filter(
          // We want to include only variants with specified currency
          priceVariant =>
            priceVariant?.versions?.some(version =>
              version?.prices?.find(price => price?.currency === currency)
            )
        ) ?? []
      )
    },
    [institution, priceList]
  )

  const getPriceVariant = useCallback(
    (
      billingFrequency: BillingFrequency,
      currency: Currency,
      employeesCount: number
    ): ListVariant | null =>
      getPriceVariants(billingFrequency, currency).find(
        priceVariant => (priceVariant?.members ?? 0) >= employeesCount
      ) ?? null,
    [getPriceVariants]
  )

  const getMessagesForVariants = (billingFrequency: BillingFrequency, currency: Currency) => {
    const variants = getPriceVariants(billingFrequency, currency)
    return variants.reduce(
      (acc, variant, i) => {
        const employees = {
          minEmployeesCount: variants?.[i - 1]?.members ?? 0,
          maxEmployeesCount: variants?.[i]?.members ?? 0,
        }
        const translation =
          variant.members === CHARGEBEE_UNLIMITED_MEMBERS ? messages.higherThan : messages.lowerThan
        return { ...acc, [variant.members ?? 0]: formatMessage(translation, employees) }
      },
      {} as Record<number, string>
    )
  }

  return {
    getPriceVariants,
    getPriceVariant,
    getMessagesForVariants,
  }
}
