import {
  type DropFirst,
  generateShortIdUrl,
  generateSurveyUrl,
  getAdministrationDomain,
  getReportingAppDomain,
} from '@faceup/utils'
import { useContext, useMemo } from 'react'
import type { Integration } from '../Pages/IntegrationsView/AppsView'
import { ThemeContext } from '../ThemeContext'
import { ThemeType } from '../locales'

type KnowledgeBaseLink = 'redaction' | 'api' | 'webhooks'

type knowledgeIntegrationLink = Extract<
  Integration,
  | 'make'
  | 'zapier'
  | 'slack'
  | 'vanta'
  | 'msTeams'
  | 'clickUp'
  | 'asana'
  | 'whatsApp'
  | 'powerBi'
  | 'atlassianJira'
  | 'ukgPro'
  | 'bambooHr'
>

type Links = Record<'supportEmail' | 'privacyLink' | 'termsLink', string> & {
  knowledgeBaseLink: Record<KnowledgeBaseLink, string> & {
    integrations: Record<knowledgeIntegrationLink, string>
  }
}

const links: Record<ThemeType, Links> = {
  [ThemeType.FaceUp]: {
    supportEmail: 'support@faceup.com',
    privacyLink: 'https://www.faceup.com/en/legal/privacy-policy',
    termsLink: 'https://www.faceup.com/en/legal/terms-of-service ',
    knowledgeBaseLink: {
      redaction: 'https://support.faceup.com/en/redacting-reports',
      api: 'https://support.faceup.com/en/api',
      webhooks: 'https://support.faceup.com/en/webhooks',
      integrations: {
        asana: 'https://support.faceup.com/en/asana-integration-via-zapier',
        atlassianJira: 'https://support.faceup.com/en/atlassian-jira-integration',
        clickUp: 'https://support.faceup.com/en/clickup-integration-via-zapier',
        make: 'https://support.faceup.com/en/make-integration',
        msTeams: 'https://support.faceup.com/en/microsoft-teams-integration-via-zapier',
        powerBi: 'https://support.faceup.com/en/integrating-faceup-statistics-with-power-bi',
        slack: 'https://support.faceup.com/en/slack-integration-via-make',
        vanta: 'https://support.faceup.com/en/faceup-integration-with-vanta',
        whatsApp: 'https://support.faceup.com/en/whatsapp-integration-via-make-or-zapier',
        zapier: 'https://support.faceup.com/en/zapier-integration',
        ukgPro: 'https://support.faceup.com/ukg-pro-and-faceup-native-integration-guide',
        bambooHr: 'https://support.faceup.com/bamboohr-and-faceup-native-integration-guide',
      },
    },
  },
  [ThemeType.NNTB]: {
    supportEmail: 'podpora@nntb.cz',
    privacyLink: 'https://www.faceup.com/en/legal/privacy-policy',
    termsLink: 'https://www.faceup.com/en/legal/terms-of-service ',
    knowledgeBaseLink: {
      redaction: 'https://support.faceup.com/cs/redigovani-oznameni',
      api: 'https://support.faceup.com/cs/api',
      webhooks: 'https://support.faceup.com/cs/webhooky',
      integrations: {
        asana: 'https://support.faceup.com/en/asana-integration-via-zapier',
        atlassianJira: 'https://support.faceup.com/en/atlassian-jira-integration',
        clickUp: 'https://support.faceup.com/en/clickup-integration-via-zapier',
        make: 'https://support.faceup.com/cs/make-integration',
        msTeams: 'https://support.faceup.com/en/microsoft-teams-integration-via-zapier',
        powerBi: 'https://support.faceup.com/en/integrating-faceup-statistics-with-power-bi',
        slack: 'https://support.faceup.com/cs/slack-and-faceup-native-integration',
        vanta: 'https://support.faceup.com/en/faceup-integration-with-vanta',
        whatsApp: 'https://support.faceup.com/en/whatsapp-integration-via-make-or-zapier',
        zapier: 'https://support.faceup.com/cs/zapier-integration',
        ukgPro: 'https://support.faceup.com/ukg-pro-and-faceup-native-integration-guide',
        bambooHr: 'https://support.faceup.com/bamboohr-and-faceup-native-integration-guide',
      },
    },
  },
}

type UseConfigForProject = () => {
  administrationDomain: string
  reportingAppDomain: string
  generateShortIdUrl: (
    ...params: DropFirst<Parameters<typeof generateShortIdUrl>>
  ) => ReturnType<typeof generateShortIdUrl>
  generateSurveyUrl: (
    ...params: DropFirst<Parameters<typeof generateSurveyUrl>>
  ) => ReturnType<typeof generateSurveyUrl>
} & Links

const useConfigForProject: UseConfigForProject = () => {
  const { project } = useContext(ThemeContext)

  const administrationDomain = useMemo(
    () => getAdministrationDomain(import.meta.env.VITE_ENVIRONMENT ?? ''),
    []
  )
  const reportingAppDomain = useMemo(
    () => getReportingAppDomain(import.meta.env.VITE_ENVIRONMENT ?? ''),
    []
  )

  return {
    ...links[project],
    administrationDomain,
    reportingAppDomain,
    generateShortIdUrl: (...params) =>
      generateShortIdUrl(import.meta.env.VITE_ENVIRONMENT ?? '', ...params),
    generateSurveyUrl: (...params) =>
      generateSurveyUrl(import.meta.env.VITE_ENVIRONMENT ?? '', ...params),
  }
}

export default useConfigForProject
